import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { StatusResponse } from './types'
import { getScenarioAnalysisStatus } from '../../utils/fetch'
import { FunctionalButton } from '../../helperComponents/_components'

// PollingResults Component
const PollingResults: React.FC<{ scenarioId: string }> = ({ scenarioId }) => {
  const [currentStatus, setCurrentStatus] = useState<StatusResponse | null>(
    null
  )
  const [isPolling, setIsPolling] = useState(false)

  // Poll until the analysis is not "in progress" or we hit a max number of attempts
  const pollScenarioAnalysis = async (
    scenarioId: string,
    pollIntervalMs = 10000,
    maxAttempts = 30
  ) => {
    setIsPolling(true)
    let attempts = 0

    while (attempts < maxAttempts) {
      attempts++

      try {
        let statusResponse = await getScenarioAnalysisStatus(scenarioId)

        // If finished & file was uploaded, transform the message
        if (
          statusResponse.finished &&
          statusResponse.note &&
          statusResponse.note.status === 'uploaded'
        ) {
          statusResponse = {
            ...statusResponse,
            message: statusResponse?.note?.note_text,
            status: 'success'
          }
        } else {
          statusResponse = {
            ...statusResponse,
            message: '###### ' + statusResponse.message,
            status: 'in progress'
          }
        }

        setCurrentStatus(statusResponse)

        if (statusResponse.status !== 'in progress') {
          setIsPolling(false)
          return true
        }

        await new Promise((resolve) => setTimeout(resolve, pollIntervalMs))
      } catch (error) {
        console.error('Error polling scenario analysis:', error)
        setCurrentStatus({
          error: 'Error polling scenario analysis',
          message: 'Error polling scenario analysis',
          status: 'error',
          finished: true
        })
        setIsPolling(false)
        return null
      }
    }

    setIsPolling(false)
    setCurrentStatus({
      error: 'Max polling attempts reached.',
      status: 'error',
      message: 'Max polling attempts reached.',
      finished: true
    })
    return { error: 'Max polling attempts reached.' }
  }

  // const checkInterval = (
  //   baseDate: Date,
  //   interval = 5 * 60 * 1000 // 5 minutes
  // ) => {
  //   const now = new Date().getTime()
  //   return now - baseDate.getTime() < interval
  // }

  return (
    <div className="container w-12 max-h-[70vh] overflow-auto no-border no-scrollbar small">
      <FunctionalButton
        initialButtonState="Analyse"
        functionToExecute={() => pollScenarioAnalysis(scenarioId)}
        className="secondary col-12 inline"
        iconAI
        doesReset
        disabled={isPolling}
        combinedButtonTitle
        loadingBar={{
          exists: true,
          estimatedTime: 80
        }}
      />

      <div className="max-h-[70vh]">
        {currentStatus &&
          currentStatus.message &&
          currentStatus.note?.updated_at && (
            <span className="col-12 small mx-4">
              <br />
              Last updated:{' '}
              {new Date(currentStatus.note.updated_at).toLocaleString()}
            </span>
          )}
        {currentStatus && currentStatus.message && (
          <div className="small rounded">
            <ReactMarkdown
              components={{
                h4: ({ node, ...props }) => (
                  <h4
                    style={{
                      fontSize: '1rem' // Adjust size as needed
                    }}
                    {...props}
                  />
                )
              }}
            >
              {currentStatus.message}
            </ReactMarkdown>
          </div>
        )}
      </div>
    </div>
  )
}

export default PollingResults
