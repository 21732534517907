import { aiSearch } from '../utils/fetch'
import React, { useState } from 'react'
import {
  FunctionalButton,
  ToggledCollapsibleBlock
} from '../helperComponents/_components'
import { IBasicIndicator } from '../utils/interfaces'

interface AISearchProps {
  addFunction: (fid: string, title: string) => void
  disabled?: boolean
}

const AISearch = ({ addFunction, disabled }: AISearchProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [results, setResults] = useState<IBasicIndicator[]>([])
  const [itemsToExclude, setItemsToExclude] = useState<string[]>([])

  const handleSearch = async () => {
    try {
      const res = await aiSearch(searchTerm, itemsToExclude)
      setResults(res)
      setItemsToExclude((prev) => [...prev, ...res.map((r) => r.fid)])
      return res.length > 0
    } catch (e) {
      console.error(e)
      setResults([])
      return false
    }
  }

  return (
    <div className="flex gap-2 col-12 p-0">
      <input
        type="text"
        className="indicators-search-input col-10 ai"
        value={searchTerm}
        placeholder="AI Search"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <FunctionalButton
        functionToExecute={handleSearch}
        initialButtonState={'AI Search'}
        iconSearchMode
        className="secondary icon scale-75"
        doesReset
        disabled={searchTerm.length === 0 || disabled}
      />
      <FunctionalButton
        functionToExecute={handleSearch}
        initialButtonState={'Retry'}
        iconReload
        className="secondary icon scale-75"
        doesReset
        disabled={searchTerm.length === 0 || results.length === 0 || disabled}
      />
      {results.length > 0 && (
        <div className="col-12 flex gap-1 ps-2 t-small text-small">
          {results.map((result, index) => (
            <div key={result.fid + '_' + index} className="flex gap-2">
              <span>
                <i>{result.title}</i>
              </span>
              <FunctionalButton
                functionToExecute={() => addFunction(result.fid, result.title)}
                initialButtonState={'Add'}
                className="border border-gray scale-75"
                iconPlusMode
                doesReset
                disabled={disabled}
              />
            </div>
          ))}
          <ToggledCollapsibleBlock
            title="Description"
            className="t-small-children info"
          >
            {results.map((result, index) => (
              <div key={result.fid + '_' + index} className="flex gap-2">
                <span>
                  <i className="text-xs">
                    {result.short_description || result.meaning}
                  </i>
                </span>
              </div>
            ))}
          </ToggledCollapsibleBlock>
        </div>
      )}
    </div>
  )
}

export default AISearch
