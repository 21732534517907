import React, { useEffect, useMemo, useState } from 'react'
import {
  FunctionalButton,
  TabbingSwitch
} from '../helperComponents/_components'
import iconClose from '../assets/icon-close.png'
import { IEntitiesSearchEntity } from '../utils/interfaces'
import { capitalise } from '../utils/functions'

const EntitiesSearch = ({
  entities,
  typeSwitcher
}: {
  entities: IEntitiesSearchEntity[]
  typeSwitcher?: boolean
}) => {
  if (!entities) return null

  const [search, setSearch] = useState('')
  const [filteredResults, setFilteredResults] = useState<
    IEntitiesSearchEntity[]
  >([])
  const [activeType, setActiveType] = useState<string>('all')

  const types = useMemo(
    () =>
      entities
        .map((item) => item.type)
        .filter((value, index, self) => self.indexOf(value) === index),
    [entities]
  )

  useEffect(() => {
    try {
      if (typeSwitcher) {
        let filtered = [...entities]

        if (activeType !== 'all') {
          filtered = filtered.filter((item) => item.type === activeType)
        }

        if (search.length > 0) {
          filtered = filtered.filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
          )
        }

        setFilteredResults(filtered)
      } else {
        let filtered: IEntitiesSearchEntity[] = []

        if (search.length > 0 && search !== 'all') {
          filtered = [...entities].filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
          )
        } else if (search === 'all') {
          filtered = [...entities]
        } else {
          filtered = []
        }

        setFilteredResults(filtered)
      }
    } catch (error) {
      console.error('Error in EntitiesSearch.tsx', error)
    }
  }, [search, activeType, entities])

  return (
    <div className="indicators-search">
      {typeSwitcher && (
        <TabbingSwitch
          numberVisible={3}
          fit
          options={[
            {
              label: 'All',
              active: activeType === 'all',
              onClick: () => setActiveType('all')
            },
            ...(types.map((type) => ({
              label: capitalise(type),
              active: type === activeType,
              onClick: () => setActiveType(type)
            })) || [])
          ]}
        />
      )}
      <input
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={
          'indicators-search-input' + (search.length > 0 ? ' active' : '')
        }
        placeholder="Search by name or type 'all'"
      />
      {search.length > 0 && (
        <button
          className="inline search-reset no-btn no-hover"
          style={{
            top: typeSwitcher ? '35px' : ''
          }}
        >
          <img
            src={iconClose}
            alt=""
            onClick={() => setSearch('')}
            className="icon inline"
          />
        </button>
      )}
      <div
        className={`indicators-search-results ${filteredResults.length === 0 ? 'no-results' : ''} ${search.length > 0 ? 'active' : ''} ${typeSwitcher ? 'type-switcher' : ''} ${typeSwitcher && search.length > 0 ? 'search-active' : ''} ${typeSwitcher && search.length === 0 ? 'search-inactive' : ''}`}
        style={{
          top: typeSwitcher ? '70px' : ''
        }}
      >
        {filteredResults.length === 0 && search.length > 0 && (
          <div className="indicators-search-result">
            <span>No results</span>
          </div>
        )}
        {filteredResults.length !== 0 &&
          filteredResults.map((item: IEntitiesSearchEntity) => (
            <div
              className="indicators-search-result col-12 flex justify-content-start ellipsis no-btn no-hover no-border"
              key={item.id}
            >
              {item.functionToExecute && (
                <FunctionalButton
                  className="col-12 flex justify-content-start ellipsis no-btn no-hover no-border"
                  initialButtonState={item.name} // Modify as required
                  functionToExecute={item.functionToExecute.function}
                  refreshOnComplete={item.functionToExecute.refreshOnComplete}
                  doesReset
                  noReturn={typeSwitcher}
                />
              )}
              {!item.functionToExecute && (
                <span className="col-12">{item.name}</span>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default EntitiesSearch
