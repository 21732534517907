import React, { useState } from 'react'
import { createNewTrendline } from '../utils/fetch'
import AISearch from './AISearch'
import IndicatorsAndTrendlinesSearch from './IndicatorsAndTrendlinesSearch'
import { IIncludedId, IIndicator } from '../utils/interfaces'
import { FunctionalCheckbox } from '../helperComponents/_components'

interface IQuickSearchProps {
  aiSearchEnabled?: boolean
  fetchedAllIndicators: IIndicator[]
  customMessage?: string
  disabled: boolean
  selection: IIncludedId[]
  updateIncluded: (id: string, title: string, add: boolean) => void
  sourceInfo: {
    entityType: string
    entityId: string
  }
  refreshEntity: () => void
}

const QuickSearch = ({
  aiSearchEnabled = false,
  selection,
  disabled,
  fetchedAllIndicators,
  sourceInfo: { entityType, entityId },
  updateIncluded,
  customMessage,
  refreshEntity
}: IQuickSearchProps) => {
  const [aiSearch, setAiSearch] = useState<boolean>(false)

  return (
    <div className="flex col-12 flex-wrap align-start m-0">
      {aiSearchEnabled && (
        <FunctionalCheckbox
          title="AI"
          disabled={disabled}
          aiMode={true}
          color="info-o"
          functionToExecute={() => {
            setAiSearch(!aiSearch)

            return true
          }}
          state={aiSearch}
        />
      )}
      {aiSearch && aiSearchEnabled && (
        <div className="col-11">
          <AISearch
            disabled={disabled}
            addFunction={(id: string, title: string) =>
              updateIncluded(id, title, true)
            }
          />
        </div>
      )}
      {!aiSearch && (
        <div
          className={aiSearchEnabled ? 'col-10 ms-[-6px]' : 'col-12 ms-[-5px]'}
        >
          <IndicatorsAndTrendlinesSearch
            indicators={fetchedAllIndicators as any}
            includedIndicators={selection
              .filter((item) => item.chart)
              .map((item) => item.fid)}
            refreshIndicators={refreshEntity}
            direction="down"
            disabled={disabled}
            addFunction={(id: string) => updateIncluded(id, '', true)}
            removeFunction={(id: string) => updateIncluded(id, '', false)}
            altFunction={async (id: string) => {
              const res = await createNewTrendline({
                indicatorFid: id,
                sourceInfo: {
                  source: entityType,
                  source_fid: entityId
                }
              })

              if (!res) {
                return false
              }

              refreshEntity()
              return true
            }}
          />
        </div>
      )}
    </div>
  )
}

export default QuickSearch
