import { isEmpty } from 'lodash'
import { IEquationPiece, IIndicator } from '../../../utils/interfaces'
import { IChartLineSettings } from '../types'
const OPERATIONS = ['+', '-', '*', '/', '(', ')']

const checkIfIndicator = (type: string) => true
// type === 'indicator' ||
// type === 'external' ||
// type === 'actuals' ||
// type === 'custom' ||
// type === 'calculated' ||
// type === 'trendline' ||
// type === 'internal' ||
// type === 'original'

const checkEquationValidity = (
  equation: IEquationPiece[],
  indicators: string[],
  setError: (error: string) => void,
  OPERATIONS: string[]
) => {
  const errorOut = (error: string) => {
    setError(error)
    console.info(equation)

    return false
  }

  if (equation.length === 1 && checkIfIndicator(equation[0].type)) {
    return true
  }

  if (equation.length === 0) {
    return errorOut('Equation is empty')
  }

  // ensure thatno pieces of the same type are next to each other unless they are operations
  for (let i = 0; i < equation.length - 1; i++) {
    if (
      equation[i].type === equation[i + 1].type &&
      !OPERATIONS.includes(equation[i].value)
    ) {
      return errorOut(
        'Equation should not have two parts of the same type next to each other'
      )
    }
  }

  // ensure that the equation starts with an indicator, number or an operator opening bracket
  if (!checkIfIndicator(equation[0].type) && equation[0].value !== '(') {
    return errorOut(
      'Equation should start with an indicator, number or an opening bracket'
    )
  }

  // ensure that the equation ends with an indicator, number or an operator closing bracket
  if (
    !checkIfIndicator(equation[0].type) &&
    equation[equation.length - 1].value !== ')'
  ) {
    return errorOut(
      'Equation should end with an indicator, number or a closing bracket'
    )
  }

  // ensure that the equation has at least one indicator
  if (
    !equation.some(
      (piece) =>
        checkIfIndicator(piece.type) && indicators.includes(piece.value)
    )
  ) {
    return errorOut('Equation should have at least one indicator')
  }

  // ensure that the equation has at least one operation
  if (!equation.some((piece) => OPERATIONS.includes(piece.value))) {
    return errorOut('Equation should have at least one operation')
  }

  // ensure that all indicators in the equation are valid and part of the indicatorsNames
  const invalidIndicators = equation.filter(
    (piece) =>
      (piece.type === 'indicator' ||
        piece.type === 'external' ||
        piece.type === 'actuals') &&
      !indicators.includes(piece.value)
  )
  if (invalidIndicators.length > 0) {
    return errorOut(
      `Equation has invalid indicators: ${invalidIndicators
        .map((ind) => ind.value)
        .join(', ')}`
    )
  }

  return true
}

const complexEquationSplitter = (equation: string) => {
  // account for both a+20 / b and a + 20/ b
  const operations = ['+', '-', '*', '/', '(', ')']

  let newEquation = equation
  operations.forEach((operation) => {
    newEquation = newEquation
      .split(operation)
      .join(` ${operation} `)
      .split('  ')
      .join(' ')
  })

  return newEquation
}

const transformStringEquationIntoEquationPieces = (
  equation: string,
  availableIndicators: IEquationPiece[],
  existingEquation: IEquationPiece[]
) => {
  try {
    const dict = 'abcdefghijklmnopqrstuvwxyz'

    const pieces = complexEquationSplitter(equation)
      .split(' ')
      .map((_, index) => {
        _ = _.toLowerCase().trim()

        if (isEmpty(_)) {
          return null
        }

        if (OPERATIONS.includes(_)) {
          return {
            value: _,
            type: 'operator'
          }
        }

        if (!isNaN(Number(_))) {
          return {
            value: _,
            type: 'number'
          }
        }

        // find the index of the indicator in the available indicators
        const indicatorIndex = dict.indexOf(_)

        if (indicatorIndex === -1) {
          return null
        }

        const existingInEquation = existingEquation.find(
          (_) => _.value === availableIndicators[indicatorIndex].value
        )

        if (existingInEquation) {
          return existingInEquation
        }

        const indicator = availableIndicators[indicatorIndex]

        if (!indicator) {
          return null
        }

        return indicator
      })
      .filter((_) => _) as IEquationPiece[]
    const equationPieces = pieces.filter(
      (_) => _ !== undefined
    ) as IEquationPiece[]

    const errors: string[] = []

    checkEquationValidity(
      equationPieces,
      availableIndicators.map((_) => _.value),
      (err) => errors.push(err),
      OPERATIONS
    )

    return {
      pieces: equationPieces,
      errorCount: errors.length,
      errors
    }
  } catch (error) {
    console.error('error', error)
    return {
      pieces: [],
      errorCount: 1,
      errors: ['Error transforming equation']
    }
  }
}

const transformEquationPiecesIntoStringEquation = (
  equation: IEquationPiece[],
  availableIndicators: IEquationPiece[]
) => {
  const dict = 'abcdefghijklmnopqrstuvwxyz'

  const pieces = equation.map((_) => {
    if (_.type === 'operator') {
      return _.value
    }

    if (_.type === 'number') {
      return _.value
    }

    const indicatorIndex = availableIndicators.findIndex(
      (ind) => ind.value === _.value
    )

    if (indicatorIndex === -1) {
      return ''
    }

    return dict[indicatorIndex]
  })

  return {
    equation: pieces.join(' ')
  }
}

const formatSelectedChartSeries = (
  propsToChange: Partial<IChartLineSettings>,
  titleOptions: string[]
) => {
  try {
    const series = (window as any).currentChart.series // HEY MORON, THIS IS NOT FINDING THE SERIES BECUASE IT SWITCHES TO ADDTRENDLINE

    if (!series) {
      console.error('No series found with the current chart')
      return false
    }

    const matchedSeries = (window as any).currentChart.series.find(
      (_: { name: string }) => titleOptions.includes(_.name)
    )

    if (!matchedSeries) {
      console.error('No series found with the current chart')
      return false
    }

    if (propsToChange.title) {
      matchedSeries.update({
        name: propsToChange.title
      })
    }

    if (propsToChange.color) {
      matchedSeries.update({
        color: propsToChange.color
      })
    }

    if (propsToChange.dashStyle) {
      matchedSeries.update({
        dashStyle: propsToChange.dashStyle
      })
    }

    if (propsToChange.width) {
      matchedSeries.update({
        lineWidth: propsToChange.width
      })
    }

    if (propsToChange.yAxis) {
      matchedSeries.update({
        yAxis: propsToChange.yAxis === 'right' ? 1 : 0
      })
    }

    if (propsToChange.data) {
      matchedSeries.update({
        data: propsToChange.data
      })
    }

    return true
  } catch (error) {
    console.error('Error formatting series by name', error)
    return false
  }
}

const evaluateIfIndicatorIsExternal = (
  indicatorId: string,
  allIndicators: IIndicator[]
) => {
  const indicator = allIndicators.find((_) => _.fid === indicatorId)

  if (!indicator || indicator.category === 'FRED') {
    return true
  }

  return false
}

const evaluateTypeOfIndicator = (
  indicatorId: string,
  allIndicators: IIndicator[]
) =>
  evaluateIfIndicatorIsExternal(indicatorId, allIndicators)
    ? 'external'
    : 'indicator'

const generatePossibleTitles = (title: string) => {
  if (title.includes('(preview) ')) {
    return [title, title.replace('(preview) ', '')]
  }
  return ['(preview) ' + title, title]
}

export {
  transformStringEquationIntoEquationPieces,
  transformEquationPiecesIntoStringEquation,
  evaluateTypeOfIndicator,
  formatSelectedChartSeries,
  generatePossibleTitles
}
