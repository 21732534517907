import React, { useState } from 'react'
import { ITag, IEntityTags } from '../utils/interfaces'
import EntitiesSearch from '../search/EntitiesSearch'
import { modifyEntityTags } from '../utils/fetch'
import { FunctionalButton } from './_components'

const EntityTags = ({
  type,
  allTags,
  entityTags,
  entityId,
  functionRefresh
}: IEntityTags) => {
  const [tagInFocus, setTagInFocus] = useState<string>('')

  const handleUpdateTag = async (
    action: 'add' | 'remove',
    tag: string | undefined
  ) => {
    const result = await modifyEntityTags({
      entity: {
        id: entityId,
        type
      },
      tag: tag || tagInFocus || '',
      action
    })
    return result
  }

  const addTag = () => {
    const res = handleUpdateTag('add', tagInFocus)
    setTagInFocus('')
    return res
  }

  return (
    <div>
      {entityTags && entityTags.length > 0 && (
        <div className="tags-list">
          {entityTags.map((tag: ITag) => (
            <div className="tag" key={tag.id}>
              <span>{tag.title}</span>
              <FunctionalButton
                functionToExecute={() => {
                  setTagInFocus(tag.title)
                  const res = handleUpdateTag('remove', tag.title)
                  setTagInFocus('')
                  return res
                }}
                initialButtonState="Remove"
                iconMinusMode
                refreshOnComplete={{
                  refreshFunction: functionRefresh,
                  exists: true
                }}
              />
            </div>
          ))}
        </div>
      )}
      <hr />

      <h4>Add one of the existing tags</h4>
      {allTags && allTags.length > 0 && (
        <EntitiesSearch
          entities={allTags
            .filter(
              (tag: ITag) =>
                !entityTags.map((tag: ITag) => tag.title).includes(tag.title)
            )
            .map((tag: ITag, index: number) => ({
              id: index,
              name: tag.title,
              type: 'tag',
              functionToExecute: {
                function: () => handleUpdateTag('add', tag.title),
                title: 'Add',
                refreshOnComplete: {
                  refreshFunction: functionRefresh,
                  exists: true
                }
              },
              is_own: true
            }))}
        />
      )}
      <hr />
      <div className="tags">
        <div className="flex gap-3 middle ">
          <h4>Or create a tag</h4>
          <FunctionalButton
            functionToExecute={addTag}
            initialButtonState="Add"
            iconPlusMode
            disabled={tagInFocus === ''}
            refreshOnComplete={{
              refreshFunction: functionRefresh,
              exists: true
            }}
            doesReset
          />
        </div>
        <div className="flex gap-2">
          <input
            type="text col-12 w-100"
            placeholder="Type a tag name"
            value={tagInFocus}
            onChange={(e) => setTagInFocus(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default EntityTags
