import React, { Fragment, useState } from 'react'
import { FunctionalButton, TabbingSwitch } from './_components'
import UploadPage from './UploadPage'
import { convertExcelDateToHTMLDate } from '../utils/transformingData'
import ChatbotComponent from './ChatbotComponent'
import EntityTags from './EntityTags'
import EntitySharingControls from './EntitySharingControls'
import {
  IDataMapped,
  IIndicator,
  IMappedDataEntry,
  IMemoEntry,
  IScenarioItem,
  ITag
} from '../utils/interfaces'
import GapAnalysis from './GapAnalysis'
import MemoMaker from './MemoMaker'
import { printPDF } from '../utils/functions'

interface DataEntityParametersProps {
  readOnly: boolean
  aiInstructions: string
  refreshFunction?: () => Promise<void>
  fid: string
  allIndicators?: IIndicator[]
  fetchedIndicatorParameters?: IIndicator
  fetchedDataTransformed: IDataMapped
  userTags: ITag[]
  refreshEntityNoClose: (contextMenu?: boolean) => void
  handleUpdateData?: (data: IMappedDataEntry) => Promise<boolean>
  handleExportData?: () => boolean
  files: File[] | null
  updateMemo?: (content: IMemoEntry[]) => Promise<boolean>
  scenarioDetails?: IScenarioItem | null
  addUpdatesToScenario?: (updates: IMemoEntry[]) => Promise<boolean>
  fetchedPDFSnapshotsRef?: any[]
  page: 'scenario' | 'indicator' | 'calculated' | 'external'
}

const DataEntityParameters = ({
  readOnly,
  refreshFunction,
  fid,
  fetchedIndicatorParameters,
  fetchedDataTransformed,
  userTags,
  refreshEntityNoClose,
  handleUpdateData,
  handleExportData,
  files,
  page,
  allIndicators,
  aiInstructions,
  updateMemo,
  scenarioDetails,
  addUpdatesToScenario,
  fetchedPDFSnapshotsRef
}: DataEntityParametersProps) => {
  const [detailsTabMode, setDetailsTabMode] = useState('details')

  return (
    <Fragment>
      <div className="entity-parameters">
        <TabbingSwitch
          options={[
            {
              label: 'Details',
              onClick: () => setDetailsTabMode('details'),
              active: detailsTabMode === 'details'
            },
            {
              label: 'Updates',
              onClick: () => setDetailsTabMode('updates'),
              active: detailsTabMode === 'updates',
              exists: !readOnly && page === 'scenario'
            },
            {
              label: 'Gap Analysis',
              onClick: () => setDetailsTabMode('gapAnalysis'),
              active: detailsTabMode === 'gapAnalysis',
              exists: !readOnly && page === 'calculated'
            },
            {
              label: 'Data',
              onClick: () => setDetailsTabMode('data'),
              active: detailsTabMode === 'data',
              exists: !readOnly && page === 'indicator'
            },
            {
              label: 'AI',
              onClick: () => setDetailsTabMode('ai'),
              active: detailsTabMode === 'ai'
            },
            {
              label: 'Tags',
              onClick: () => setDetailsTabMode('tags'),
              active: detailsTabMode === 'tags',
              exists: !readOnly && page !== 'external'
            },
            {
              label: 'Sharing',
              onClick: () => setDetailsTabMode('sharing'),
              active: detailsTabMode === 'sharing',
              exists: !readOnly && page !== 'external'
            }
          ]}
          numberVisible={7}
          fit={page !== 'external'}
          className="no-border col-12"
        />
      </div>
      {detailsTabMode === 'data' &&
        page === 'indicator' &&
        fetchedIndicatorParameters && (
          <div className="col-12">
            <UploadPage
              fullFetchedIndicatorsParameters={[fetchedIndicatorParameters]}
              refreshFunction={refreshFunction || (() => Promise.resolve())}
              singleIndicatorMode={{
                exists: true,
                indicatorId: fid
              }}
              mode="table"
              customData={(() => {
                const data = fetchedDataTransformed?.data.find(
                  (item: any) => item.title === fetchedIndicatorParameters?.fid
                )
                if (!data) {
                  return { dates: [], values: [], title: '' }
                }

                return {
                  dates: data.dates.map((date: any) =>
                    convertExcelDateToHTMLDate(date)
                  ),
                  values: data.values,
                  title: data.title
                }
              })()}
              customPostFunction={handleUpdateData}
            />
          </div>
        )}

      {detailsTabMode === 'details' && page !== 'scenario' && (
        <textarea
          className="description default-text p-2"
          contentEditable={false}
          readOnly
          value={
            fetchedIndicatorParameters?.meaning || 'No description provided'
          }
        />
      )}
      {detailsTabMode === 'details' && page === 'scenario' && (
        <MemoMaker
          widthAvailable={700}
          handleUpdateMemo={updateMemo || (() => Promise.resolve(false))}
          intialState={
            scenarioDetails?.description_blocks || [
              {
                type: 'text',
                content:
                  scenarioDetails?.scenario_overview ||
                  'No description provided',
                config: {
                  className: 'col-12',
                  source: ''
                }
              }
            ]
          }
        />
      )}

      {detailsTabMode === 'updates' && (
        <MemoMaker
          widthAvailable={700}
          handleUpdateMemo={
            addUpdatesToScenario || (() => Promise.resolve(false))
          }
          intialState={
            scenarioDetails?.updates || [
              {
                type: 'text',
                content: 'No updates',
                config: {
                  className: 'col-12',
                  source: ''
                }
              }
            ]
          }
        />
      )}

      {detailsTabMode === 'gapAnalysis' && page === 'calculated' && (
        <GapAnalysis
          indicators={allIndicators || []}
          indicatorData={fetchedDataTransformed}
          indicatorId={fid}
          refreshFunction={refreshEntityNoClose}
        />
      )}

      <div
        style={{ display: detailsTabMode === 'ai' ? 'block' : 'none' }}
        className="flex-row col-12"
      >
        <ChatbotComponent
          page="entity"
          assistantOn={true}
          providedFiles={files}
          instructions={aiInstructions || ''}
          clearFiles={() => (files = null)}
        />
      </div>

      {detailsTabMode === 'tags' && (
        <div className="description flex-row col-12">
          <div className="entity-info-block col-12 default-text">
            {!readOnly && (
              <EntityTags
                type="indicator"
                allTags={userTags}
                entityTags={
                  fetchedIndicatorParameters?.tags ||
                  scenarioDetails?.tags ||
                  []
                }
                entityId={fid}
                functionRefresh={refreshEntityNoClose}
              />
            )}
          </div>
        </div>
      )}

      {detailsTabMode === 'sharing' &&
        fetchedIndicatorParameters &&
        page !== 'scenario' && (
          <div className="description flex-row col-12">
            <div className="entity-info-block col-12 default-text">
              <FunctionalButton
                className="secondary inline"
                functionToExecute={handleExportData || (() => false)}
                disabled={readOnly}
                initialButtonState={'Export Data'}
              />
              <EntitySharingControls
                entityType="indicator"
                entityId={fid}
                ChannelContentPiece={
                  fetchedIndicatorParameters.ChannelContentPiece
                }
                refreshFunction={refreshEntityNoClose}
                initialDescription={fetchedIndicatorParameters.meaning}
              />
            </div>
          </div>
        )}

      {detailsTabMode === 'sharing' && page === 'scenario' && (
        <div className="description col-12 default-text">
          {fetchedPDFSnapshotsRef &&
            fetchedPDFSnapshotsRef.length > 0 &&
            fetchedPDFSnapshotsRef.map((item: any, index) => (
              <div key={index}>
                <a
                  className="full my-1 col-12 pointer flex"
                  onClick={() => {
                    printPDF({
                      pdfData: item.content,
                      filename: item.filename
                    })
                  }}
                >
                  {item.filename}
                </a>
              </div>
            ))}
          <EntitySharingControls
            entityType="scenario"
            entityId={fid}
            ChannelContentPiece={scenarioDetails?.ChannelContentPiece ?? null}
            refreshFunction={refreshEntityNoClose}
            initialDescription={scenarioDetails?.scenario_overview}
          />
        </div>
      )}
    </Fragment>
  )
}

export default DataEntityParameters
