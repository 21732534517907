import React, { useEffect, useState } from 'react'
import {
  FunctionalButton
  // FunctionalToggle
} from '../../helperComponents/_components'
import RangeSlider2 from '../../helperComponents/RangeSlider2'
import { IControls } from '../../utils/interfaces'

const ControlsPanel = ({
  controls,
  fullDates,
  handlePreview,
  handleSubmit
}: {
  controls: {
    chartMode: string
    selectedDataBoundaries: string[]
    isRelative: boolean
    inverse: boolean
    mode: 'values' | 'deviations' | 'residuals'
  }
  fullDates: string[]
  handlePreview: (controls: IControls) => void
  handleSubmit: (mode: 'values' | 'deviations' | 'residuals') => void
}) => {
  const [chartMode, setChartMode] = useState<string>(controls.chartMode)
  const [selectedDataBoundaries, setSelectedDataBoundaries] = useState<
    string[]
  >(controls.selectedDataBoundaries)
  const [isRelative, setIsRelative] = useState<boolean>(controls.isRelative)
  const [inverse, setInverse] = useState<boolean>(controls.inverse)
  const [dataMode, setDataMode] = useState<
    'values' | 'deviations' | 'residuals'
  >(controls.mode)

  useEffect(() => {
    setChartMode(controls.chartMode)
    setSelectedDataBoundaries(controls.selectedDataBoundaries)
    setIsRelative(controls.isRelative)
    setInverse(controls.inverse)
    setDataMode(controls.mode)
  }, [controls])

  return (
    <div className="container w-12 no-scrollbar no-borders p-0">
      <div className="flex-column">
        <div className="flex m-0 p-1 col-12">
          <label>Line:</label>
          <select
            className="inline"
            value={chartMode}
            onChange={(e) => setChartMode(e.target.value)}
          >
            <option value="exponential">Exponential</option>
            <option value="linear">Linear</option>
          </select>
          <RangeSlider2
            dataArray={fullDates}
            initialDetails={selectedDataBoundaries}
            selectedDetails={selectedDataBoundaries}
            dateSliderMode={true}
            iconMode={'calculate'}
            customStyles={{
              width: 400,
              padding: '6px 20px 6px 5px'
            }}
            noSlider={true}
            noApplyButton={true}
            onApply={() => undefined}
            handleChange={(value: string[]) => {
              setSelectedDataBoundaries(value)
            }}
          />
        </div>

        <div className="flex m-0 p-1 col-12">
          <label>Data:</label>
          <select
            className="inline"
            value={dataMode || 'values'}
            onChange={(e) => {
              setDataMode(e.target.value as 'values' | 'deviations')
              if (e.target.value !== 'deviations') {
                setIsRelative(false)
              }
            }}
          >
            <option value="values">Values</option>
            <option value="deviations">Deviations</option>
          </select>
          {dataMode !== 'deviations' && (
            <select
              className="inline"
              value={dataMode}
              onChange={(e) =>
                setDataMode(e.target.value as 'values' | 'residuals')
              }
            >
              <option value="values">Trendline</option>
              <option value="residuals">Residuals</option>
            </select>
          )}
          {dataMode === 'deviations' && (
            <select
              className="inline"
              value={isRelative ? 'relative' : 'absolute'}
              onChange={(e) => setIsRelative(e.target.value === 'relative')}
            >
              <option value="absolute">Absolute</option>
              <option value="relative">Relative</option>
            </select>
          )}
        </div>
      </div>
      <br />
      <div className="flex col-12 align-items-start gap-1">
        {/* <FunctionalButton
            className="teriary wide"
            initialButtonState={'Discard'}
            iconDelete
            combinedButtonTitle
            functionToExecute={closeModal}
            doesReset
          /> */}
        <FunctionalButton
          className="secondary inline"
          initialButtonState={'Preview'}
          functionToExecute={() =>
            handlePreview({
              chartMode,
              selectedDataBoundaries,
              isRelative,
              inverse,
              mode: dataMode as 'values' | 'deviations'
            })
          }
          doesReset
        />
        <FunctionalButton
          className="primary inline"
          initialButtonState={'Submit'}
          combinedButtonTitle
          iconSaveMode
          functionToExecute={() => handleSubmit(dataMode)}
          doesReset
        />
      </div>
    </div>
  )
}
export default ControlsPanel
