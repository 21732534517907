import React, { useEffect, useState } from 'react'
import {
  CreateTrendlineNewProps,
  ICalculateDeviationsParamsReturnKeys,
  IControls,
  IRow
} from '../utils/interfaces'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import { Spinner } from '../helperComponents/_components'
import {
  generateRandomId,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
import { previewTrendline } from '../utils/fetch'
import ControlsPanel from './utils/ControlsPanel'

const CreateTrendlineNew = ({
  equationPiece,
  frequency,
  updateIndicator
}: CreateTrendlineNewProps) => {
  const [data, setData] = useState<IRow[]>([])
  const [fullDates, setFullDates] = useState<string[]>([])
  const initialControls = equationPiece.trendlineParameters
    ? {
        ...equationPiece.trendlineParameters,
        selectedDataBoundaries:
          transformFetchedRangedDetailsScenariotoRangeInputs(
            equationPiece.trendlineParameters.rangeChosen
          ),
        rangeChosen: undefined,
        isRelative: equationPiece.trendlineParameters.standardDeviationRelative
      }
    : {
        chartMode: 'exponential',
        selectedDataBoundaries: ['', ''],
        isRelative: false,
        inverse: false,
        mode: 'values' as 'values' | 'deviations' | 'residuals'
      }

  const [controls, setControls] = useState(initialControls)

  const [keys, setKeys] = useState<ICalculateDeviationsParamsReturnKeys>({
    valuesTitle: '',
    trendlineTitle: '',
    residualTitle: '',
    residualOverDevTitle: ''
  })

  const handlePreview = async (controls: IControls) => {
    try {
      const response = await previewTrendline({
        equationPiece,
        frequency,
        rangeChosen: `[${controls.selectedDataBoundaries[0]},${controls.selectedDataBoundaries[1]})`,
        inverse: controls.inverse,
        standardDeviationRelative: controls.isRelative,
        chartMode: controls.chartMode
      })

      if (response) {
        setData(response.merged)
        setFullDates(response.fullDates)
        setKeys(response.keys)
        setControls({
          chartMode: response.trendline.standard_deviation_chart_mode,
          selectedDataBoundaries:
            transformFetchedRangedDetailsScenariotoRangeInputs(
              response.trendline.range_chosen_deviation
            ),
          isRelative: controls.isRelative,
          inverse: controls.inverse,
          mode: controls.mode
        })
      }

      return true
    } catch (error) {
      console.error("Error in 'handlePreview' function")
      return false
    }
  }

  const handleSubmit = (mode: 'values' | 'deviations' | 'residuals') =>
    updateIndicator({
      ...equationPiece,
      trendlineParameters: {
        frequency,
        rangeChosen: `[${controls.selectedDataBoundaries[0]},${controls.selectedDataBoundaries[1]})`,
        inverse: controls.inverse,
        standardDeviationRelative: controls.isRelative || false,
        chartMode: controls.chartMode,
        mode
      }
    })

  useEffect(() => {
    handlePreview(initialControls)
  }, [])

  if (!data || !fullDates || data.length === 0) {
    return <Spinner />
  }

  return (
    <div className="entity-blocks">
      <ControlsPanel
        controls={controls}
        handlePreview={handlePreview}
        handleSubmit={handleSubmit}
        fullDates={fullDates}
      />
      <div className="container w-12 auto no-scrollbar">
        <div className="entity-chart">
          <HighChartsLinearChart
            pageDetails={{
              type: 'indicator',
              title: 'Trendline Preview',
              fid: generateRandomId(),
              refresh: () => undefined
            }}
            page="entity"
            chartOptions={{
              xAxisTitle: 'Month',
              yAxisTitle: 'Value',
              title: 'New Trendline Chart',
              reactive: true,
              mediumSize: true,
              oneAxis: true
            }}
            chartData={{
              fetchedData: data || [],
              fullDates,
              titles: [{ title: '' }],
              filteredKeys: [
                keys.valuesTitle,
                keys.trendlineTitle,
                keys.residualTitle
              ],
              keysImportant: true
            }}
            componentOptions={{
              secondary: true
            }}
          />
        </div>
      </div>
      {/* <div className="container w-12 auto no-scrollbar">
        <div className="entity-chart">
          <HighChartsLinearChart
            pageDetails={{
              type: 'indicator',
              title: 'Trendline Preview',
              fid: generateRandomId(),
              refresh: () => undefined
            }}
            page="entity"
            chartOptions={{
              xAxisTitle: 'Month',
              yAxisTitle: 'Value',
              title: 'Residuals Chart',
              reactive: true,
              mediumSize: true
            }}
            chartData={{
              fetchedData: data || [],
              fullDates,
              titles: [{ title: '' }],
              filteredKeys: [keys.residualOverDevTitle, keys.residualTitle],
              keysImportant: true
            }}
          />
        </div>
      </div> */}
    </div>
  )
}

export default CreateTrendlineNew
