import React, { useState } from 'react'
import { FunctionalButton } from '../../helperComponents/_components'
import CollapsibleSidePanel from '../../helperComponents/CollapsibleSidePanel'
import ChartLines from '.'
import { ChartLinesProps } from './types'

const ChartLinesCollapsiblePanel = (props: ChartLinesProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false)

  return (
    <>
      <FunctionalButton
        className="teriary center no-border no-btn icon"
        noReturn
        functionToExecute={() => {
          setIsPanelOpen(!isPanelOpen)
        }}
        doesReset
        initialButtonState={'Series Controls'}
        iconSettings
      />
      <CollapsibleSidePanel
        isOpen={isPanelOpen}
        onToggle={() => setIsPanelOpen(!isPanelOpen)}
        functionalButton={undefined}
        position="right full"
      >
        <ChartLines {...props} />
      </CollapsibleSidePanel>
    </>
  )
}

export default ChartLinesCollapsiblePanel
