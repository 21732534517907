import React, { useEffect, useState } from 'react'
import { ISidePanel } from '../utils/interfaces'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  IconHome,
  IconSettings,
  IconLibrary,
  IconUpload,
  IconBurger,
  IconOther,
  IconChannel,
  IconSearch
} from '../helperComponents/Icons'
import EntitiesSearch from '../search/EntitiesSearch'

const SidePanel = ({
  entities,
  channelsEntitiesSearch,
  handleContextMenuSidePanel,
  resetContextMenu,
  openSettings
}: ISidePanel) => {
  const [collapsed, setCollapsed] = useState(true)

  const collapseSidePanel = (newState: boolean) => {
    const isMobile =
      /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ||
      window.innerWidth < 768

    const widthToSet = newState
      ? '60px'
      : isMobile
        ? '100vw'
        : 'max(20vw, 300px)'

    document.documentElement.style.setProperty('--side_panel_with', widthToSet)

    if (!newState) {
      const timeout = setTimeout(() => {
        setCollapsed(newState)
      }, 200)

      return () => clearTimeout(timeout)
    }

    setCollapsed(newState)
  }

  useEffect(() => {
    // export collapse side panel to the window object
    window.collapseSidePanel = collapseSidePanel
  }, [])

  return (
    <div className={`site-side-panel${collapsed ? ' collapsed' : ''}`}>
      <div className="site-side-panel-header">
        <div className="flex gap-2 icons pt-2">
          <button
            className="no-btn py-3 icon inline default-text no-border my-2"
            onClick={() => collapseSidePanel(!collapsed)}
          >
            <IconBurger />
          </button>
          {!collapsed && (
            <button
              onClick={window.switchFunctions.home}
              className="flex middle m-0 logo no-btn"
            >
              <img className="icon circle p-1" src="/favicon.ico" alt="" />
              Chartit-360
            </button>
          )}
          <button
            className="no-btn icon py-3 default-text no-border"
            onClick={window.switchFunctions.home}
          >
            <IconHome /> {collapsed ? '' : 'Home'}
            {/* {collapsed && <span className="subtitle">Home</span>} */}
          </button>
          <button
            className="no-btn icon py-3 default-text no-border"
            onClick={() => window.switchFunctions.search()}
          >
            <IconSearch /> {collapsed ? '' : ' Advanced Search'}
            {/* {collapsed && <span className="subtitle">Search</span>} */}
          </button>
          <button
            className="no-btn icon py-3 default-text no-border"
            onClick={() =>
              window.switchFunctions.channel(window.globalSettings.channel_id)
            }
          >
            <IconChannel /> {collapsed ? '' : 'Your Channel'}
            {/* {collapsed && <span className="subtitle">Channel</span>} */}
          </button>
          {collapsed && <div className="separator my-2"></div>}
          {collapsed && (
            <button
              className="no-btn icon py-3 default-text no-border"
              onClick={() => collapseSidePanel(!collapsed)}
            >
              <IconLibrary />
              {/* <span className="subtitle">Library</span> */}
            </button>
          )}
          {collapsed && (
            <button
              className="no-btn icon py-3 default-text no-border"
              onClick={() => collapseSidePanel(!collapsed)}
            >
              <IconOther />
              {/* <span className="subtitle">Subscriptions</span> */}
            </button>
          )}
        </div>
      </div>
      {!collapsed && <div className="separator my-2"></div>}
      <div
        className="site-side-panel-content"
        style={{ display: collapsed ? 'none' : 'block' }}
        onClick={resetContextMenu}
      >
        <h3 className="ps-3 flex middle my-1 mt-4">
          <div
            style={{
              scale: '0.8'
            }}
          >
            <IconLibrary />
          </div>
          Your Library
          <button
            className="no-btn icon default-text no-border p-0 no-hover"
            onClick={handleContextMenuSidePanel}
            style={{
              position: 'absolute',
              right: '0.5rem'
            }}
          >
            <IconUpload />
          </button>
        </h3>
        <div
          className="px-3"
          style={{
            height: '30%'
          }}
        >
          <EntitiesSearch entities={entities} typeSwitcher={true} />
        </div>
        <div
          className="separator mb-2"
          style={{
            marginTop: '80px'
          }}
        ></div>
        <h3 className="ps-3 flex middle my-1 mt-4">
          <button
            className="no-btn default-text no-border no-hover wider flex"
            onClick={() => window.switchFunctions.channels()}
          >
            <div
              className="icon"
              style={{
                scale: '0.75'
              }}
            >
              <IconOther />
            </div>
            <span>Subscriptions</span>
          </button>
        </h3>
        <div
          className="px-3"
          style={{
            height: '25%'
          }}
        >
          <EntitiesSearch
            entities={channelsEntitiesSearch}
            typeSwitcher={true}
          />
        </div>
      </div>

      <div className="site-side-panel-footer">
        <div className="flex gap-3 middle">
          <button
            className="send-update no-btn no-hover icon flex center middle t-large"
            onClick={openSettings}
          >
            <IconSettings />
          </button>
        </div>
      </div>
    </div>
  )
}

export default SidePanel
