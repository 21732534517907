import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  IEntitiesSearchEntity,
  IChannelContentPiece,
  IThumbnail,
  ICustomContextMenuSettings,
  IMemo
} from '../utils/interfaces'
import {
  getChannelDataById,
  getMemoLink,
  initialSharing,
  subscribeToChannel,
  unsubscribeFromChannel,
  updateUserSettings
} from '../utils/fetch'
import PopupModal from '../helperComponents/PopUpModal'
import EntitySharingControls from '../helperComponents/EntitySharingControls'
import { capitalise } from '../utils/functions'
import {
  EntityHeader,
  InlineSpinner,
  Spinner,
  TabbingSwitch,
  Thumbnails
} from '../helperComponents/_components'
import EntitiesSearch from '../search/EntitiesSearch'
import {
  Icon3Dots,
  IconUploadNew,
  // ImagePlaceHolderNoImage,
  TickFail,
  TickSuccess
} from '../helperComponents/Icons'
import CustomContextMenu from '../helperComponents/CustomContextMenu'
import UpdatesFeed from '../helperComponents/UpdatesFeed'
import { crawlAllLinksFromMemos } from '../utils/transformingData'

const ChannelPage = ({
  entities,
  channelId
}: {
  entities: IEntitiesSearchEntity[]
  channelId: string
}) => {
  const channelContentPieces = useRef<IChannelContentPiece[]>([])
  const [memos, setMemos] = useState<IMemo[]>([])
  const [subscribersCount, setSubscribersCount] = useState<number>(0)
  const contextMenuDefaultState = {
    show: false,
    top: 0,
    left: 0 - 500
  }
  const [contextMenuDetails, setContextMenuDetails] =
    useState<ICustomContextMenuSettings>(contextMenuDefaultState)
  const resetContextMenuDetails = () =>
    contextMenuDetails.show && setContextMenuDetails(contextMenuDefaultState)
  const [username, setUsername] = useState<string>('')
  const [tabbingSwitchSection, setTabbingSwitchSection] =
    useState<string>('memos')
  const [memosTabbingSwitchSection, setMemosTabbingSwitchSection] =
    useState<string>('feed')
  const [crawledLinks, setCrawledLinks] = useState<string[]>([])
  const [entityInFocus, setEntityInFocus] =
    useState<IChannelContentPiece | null>(null)
  const [loading, setLoading] = useState(true)
  const [modalAddNew, setModalAddNew] = useState(false)
  const [entityToShare, setEntityToShare] =
    useState<IEntitiesSearchEntity | null>(null)
  const [modalChannelSettings, setModalChannelSettings] = useState(false)
  const [imageLink, setImageLink] = useState<string>('')
  const [isOwn, setIsOwn] = useState<boolean>(false)
  const [subscribed, setSubscribed] = useState<boolean>(false)
  const [uploadState, setUploadState] = useState<
    'loading' | 'error' | 'success' | 'ready'
  >('ready')

  const initialiseSharing = async () => {
    if (!entityToShare || !isOwn) return false

    const res = await initialSharing({
      sharingTier: 0,
      entityId: entityToShare.id,
      entityType: entityToShare.type.toLowerCase()
    })

    return res
  }

  const reset = () => {
    setEntityInFocus(null)
    setModalAddNew(false)
    resetContextMenuDetails()
    setEntityToShare(null)
    setModalChannelSettings(false)
    setUploadState('ready')
  }

  const handleContextMenuDetails = (e: any) => {
    e.preventDefault()

    if (contextMenuDetails.show) {
      resetContextMenuDetails()
      return
    }

    reset()
    setContextMenuDetails({
      show: true,
      top: e.pageY,
      left: e.pageX - 210
    })
  }

  const updateProfileInformation = async () => {
    if (!isOwn) return false

    const res = await updateUserSettings({
      ...window.globalSettings,
      username,
      profile_image: imageLink
    })

    if (res) {
      window.location.reload()
      setModalChannelSettings(false)
    }

    return res
  }

  const initialFetch = async (channelId: string) => {
    setLoading(true)
    reset()
    const res = await getChannelDataById(channelId)
    const {
      username,
      profile_image,
      data,
      isOwn,
      isSubscribed,
      subscribersCount,
      fetchedMemos
    } = res
    setSubscribersCount(subscribersCount)
    const links = crawlAllLinksFromMemos(fetchedMemos)
    channelContentPieces.current = data
    setUsername(username)
    setCrawledLinks(links)
    setIsOwn(isOwn)
    setMemos(fetchedMemos)
    setSubscribed(isSubscribed)
    setImageLink(profile_image)
    setLoading(false)
  }

  const refreshChannel = async () => {
    await initialFetch(channelId)
  }

  const transformedEntities: IEntitiesSearchEntity[] = useMemo(
    () =>
      entities
        .filter(
          (entity) =>
            !channelContentPieces.current.some(
              (ref) => ref.source_fid === entity.id
            ) && entity.is_own
        )
        .map((entity) => ({
          ...entity,
          functionToExecute: {
            function: () => setEntityToShare(entity),
            title: 'Initialise Sharing'
          }
        })),
    [entities]
  )

  const handleAddImage = async (file: File) => {
    const imageType = file.type.split('/')[1]
    const link = await getMemoLink(file, imageType)

    if (!link) {
      setUploadState('error')
      return
    }

    setUploadState('success')
    setImageLink(link)
    return link
  }

  useEffect(() => {
    refreshChannel()
  }, [channelId])

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="entity channel default-text">
      <EntityHeader
        subtitle="Channel"
        ownerInfo={{ profileImage: imageLink, username }}
        title={username}
        description={`${subscribersCount} subscribers`}
      />
      <div className="entity-blocks">
        <div className="container w-12 fit">
          {' '}
          <div className="entity-parameters justify-content-start">
            <TabbingSwitch
              fit
              numberVisible={4}
              options={[
                {
                  label: 'Memos',
                  onClick: () => setTabbingSwitchSection('memos'),
                  active: tabbingSwitchSection === 'memos',
                  className: 'flex middle center'
                },
                {
                  label: 'All Entities',
                  onClick: () => setTabbingSwitchSection('entities'),
                  active: tabbingSwitchSection === 'entities',
                  className: 'flex middle center'
                }
              ]}
            />
            <button
              className="no-btn flex no-hover"
              onClick={handleContextMenuDetails}
            >
              <Icon3Dots />
            </button>
          </div>
          {tabbingSwitchSection === 'memos' && (
            <div className="entity-parameters justify-content-start">
              <TabbingSwitch
                fit
                numberVisible={4}
                options={[
                  {
                    label: 'Feed',
                    onClick: () => setMemosTabbingSwitchSection('feed'),
                    active: memosTabbingSwitchSection === 'feed',
                    className: 'flex middle center'
                  },
                  {
                    label: 'All Memos',
                    onClick: () => setMemosTabbingSwitchSection('memos'),
                    active: memosTabbingSwitchSection === 'memos',
                    className: 'flex middle center'
                  },
                  {
                    label: 'Sources',
                    onClick: () => setMemosTabbingSwitchSection('sources'),
                    active: memosTabbingSwitchSection === 'sources',
                    exists: crawledLinks.length > 0,
                    className: 'flex middle center'
                  }
                ]}
                tabbingLook
              />
            </div>
          )}
        </div>

        <div className="container w-12 fit">
          {tabbingSwitchSection === 'memos' && (
            <div className="h-[30px] no-border"></div>
          )}
          {(tabbingSwitchSection === 'entities' ||
            (tabbingSwitchSection === 'memos' &&
              memosTabbingSwitchSection === 'memos')) && (
            <Thumbnails
              itemsInRow={4}
              thumbnails={[
                {
                  title: 'Add New',
                  subtitle: '',
                  image: '',
                  details: 'Click to share a new entity to your channel',
                  onClick: () => setModalAddNew(true),
                  tags: [],
                  addNewMode: true
                },
                ...channelContentPieces.current
                  .filter((entity) => {
                    if (
                      tabbingSwitchSection === 'memos' &&
                      entity.source_type !== 'memo'
                    ) {
                      return false
                    }

                    return isOwn ? entity.is_own : !entity.is_own
                  })
                  .map(
                    (entity): IThumbnail => ({
                      title: entity.title,
                      subtitle: capitalise(entity.source_type),
                      image: entity.link || '',
                      details: entity.short_description || '',
                      onClick: () =>
                        window.switchFunctions[
                          entity.source_type as keyof typeof window.switchFunctions
                        ](entity.source_fid),
                      editFunction: isOwn
                        ? () => setEntityInFocus(entity)
                        : undefined,
                      ownerInfo: entity.owner_info,
                      tags: entity.tags
                    })
                  )
              ].filter((entity) =>
                !isOwn ? entity.title !== 'Add New' : true
              )}
            />
          )}
          {memosTabbingSwitchSection === 'sources' && (
            <ul className="no-border">
              {crawledLinks.map((link, index) => (
                <li key={index}>
                  <a href={link} target="_blank" rel="noreferrer">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          )}
          {memosTabbingSwitchSection === 'feed' && (
            <UpdatesFeed memos={memos} isOwn={isOwn} />
          )}
        </div>
      </div>
      {entityInFocus !== null && isOwn && (
        <PopupModal
          isOpen={entityInFocus !== null}
          onClose={() => setEntityInFocus(null)}
          title={`Edit Sharing for ${entityInFocus.title}`}
          size="large"
          noChanges
        >
          <EntitySharingControls
            entityType="scenario"
            entityId={''}
            ChannelContentPiece={entityInFocus}
            refreshFunction={refreshChannel}
          />
        </PopupModal>
      )}
      {modalAddNew && transformedEntities && isOwn && (
        <PopupModal
          isOpen={modalAddNew}
          onClose={() => setModalAddNew(false)}
          title="Choose an entity to share to your channel"
          size="large"
          noChanges
          handleSubmit={initialiseSharing}
          saveButtonExists
          refreshOnComplete={{
            exists: true,
            refreshFunction: refreshChannel
          }}
          saveButtonDisabled={!entityToShare}
        >
          {entityToShare && (
            <h2 className="col-12 p-2">{entityToShare.name} selected</h2>
          )}
          <EntitiesSearch entities={transformedEntities} typeSwitcher={true} />
        </PopupModal>
      )}
      <CustomContextMenu
        contextMenuSettings={contextMenuDetails}
        menu={[
          {
            onClick: () => {
              reset()
              setModalChannelSettings(!modalChannelSettings)
            },
            title: 'Edit Details',
            noAccess: !isOwn
          },
          {
            onClick: () => {
              reset()
              subscribed
                ? unsubscribeFromChannel(channelId)
                : subscribeToChannel(channelId)
              refreshChannel()
            },
            title: subscribed ? 'Unsubscribe' : 'Subscribe',
            noAccess: isOwn
          },
          {
            onClick: () => {
              reset()
              navigator.clipboard.writeText(window.location.href)
              return true
            },
            title: 'Copy URL'
          }
        ]}
      />

      {modalChannelSettings && isOwn && (
        <PopupModal
          isOpen={modalChannelSettings}
          onClose={() => setModalChannelSettings(false)}
          title="Channel Settings"
          size="large"
          noChanges
          saveButtonExists
          handleSubmit={updateProfileInformation}
          saveButtonDisabled={!username}
        >
          <div className="profile">
            <div className="profile-image">
              {imageLink && (
                <img className="" src={imageLink} alt="Thumbnail" />
              )}
              <div className="edit-text pointer">
                <div className={'file-upload flex middle'}>
                  <label htmlFor="file-input" className="file-input">
                    {
                      {
                        loading: <InlineSpinner />,
                        error: <TickFail />,
                        success: <TickSuccess />,
                        ready: <IconUploadNew />
                      }[uploadState]
                    }
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    name="file-input"
                    onChange={async (e) => {
                      if (e.target.files) {
                        handleAddImage(e.target.files[0])
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="profile-info">
              <input
                className="name"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Your Channel Name"
              />
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  )
}

export default ChannelPage
