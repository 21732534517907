import React, { useEffect, useState } from 'react'
import { IEquationPiece, IIndicator } from '../../utils/interfaces'
import {
  FunctionalButton,
  ToggleInfo
} from '../../helperComponents/_components'
import { checkCalcEquationChanges } from '../../utils/transformingData'
import Instructions from '../helperComponents/blocks'
import {
  AggregationMethod,
  CreateSeriesFromDataAndModeType,
  Frequency
} from '../interfaces'
import {
  transformEquationPiecesIntoStringEquation,
  transformStringEquationIntoEquationPieces
} from './utils/functions'
import PopupModalNew from '../../helperComponents/PopUpModalNew'
import CreateTrendlineNew from '../../trendlines/CreateTrendlineNew'
import {
  generateRandomId,
  transformFetchedRangedDetailsScenariotoRangeInputsSlash
} from '../../utils/functions'
const dict = 'abcdefghijklmnopqrstuvwxyz'

const CombineIndicatorFormula = ({
  updateEquationFunction,
  initialAvailableIndicators,
  currentAvailableIndicators,
  allIndicators,
  frequency,
  initialEquation,
  children
}: {
  allIndicators: IIndicator[]
  initialAvailableIndicators: IEquationPiece[]
  initialEquation: IEquationPiece[]
  currentAvailableIndicators: IEquationPiece[]
  frequency: Frequency
  updateEquationFunction: (
    equation: IEquationPiece[],
    availableIndicators: IEquationPiece[],
    noFetch?: boolean
  ) => Promise<boolean> | boolean
  children?: React.ReactNode
}) => {
  try {
    const [error, setError] = useState('')
    const [info, setInfo] = useState('')
    const [availableIndicators, setAvailableIndicators] = useState<
      IEquationPiece[]
    >([])

    const [renderedStringEquation, setRenderedEquation] = useState(
      transformEquationPiecesIntoStringEquation(
        initialEquation,
        initialAvailableIndicators
      ).equation
    )
    const [equation, setEquation] = useState<IEquationPiece[]>(initialEquation)
    const [showCAGR, setShowCAGR] = useState<string[]>([])
    const [showTrendline, setShowTrendline] = useState<string[]>(
      currentAvailableIndicators
        .filter((_) => _.trendlineParameters)
        .map((_) => _.value)
    )

    const updateIndicatorsChange = (indicator: IEquationPiece) => {
      try {
        const { value: fid } = indicator

        const found = availableIndicators.find((_) => _.value === fid)
        const foundInEquation = equation.find((_) => _.value === fid)
        let availableIndicatorsCopy = [...availableIndicators]
        let equationCopy = [...equation]

        if (found) {
          availableIndicatorsCopy = availableIndicatorsCopy.map((_) =>
            _.value === fid ? indicator : _
          )
          setAvailableIndicators(availableIndicatorsCopy)
        }

        if (foundInEquation) {
          equationCopy = equationCopy.map((_) =>
            _.value === fid ? indicator : _
          )
          setEquation(equationCopy)
        }

        return updateEquationFunction(
          equationCopy,
          availableIndicatorsCopy,
          true
        )
      } catch (error) {
        console.error('Error in updateIndicatorsChange')
        return false
      }
    }

    const resetEquation = () => {
      setEquation(initialEquation)
      updateEquationFunction(initialEquation, initialAvailableIndicators)
      setAvailableIndicators(initialAvailableIndicators)
      setRenderedEquation(
        transformEquationPiecesIntoStringEquation(
          initialEquation,
          availableIndicators
        ).equation
      )

      return true
    }

    const handleUpdate = () => {
      if (renderedStringEquation === '') {
        setError('Please enter an equation')
        return false
      }

      const checked = transformStringEquationIntoEquationPieces(
        renderedStringEquation,
        availableIndicators,
        equation
      )

      if (!checked || checked.errorCount > 0) {
        const joinedErrors = checked.errors.join(';\n\n')
        setError(joinedErrors)

        return false
      }

      const res = updateEquationFunction(checked.pieces, availableIndicators)

      return res
    }

    useEffect(() => {
      setError('')
    }, [renderedStringEquation])

    useEffect(() => {
      if (error !== '' && $('.equation-display').html() === '') {
        setInfo('Try reseting the equation')
      } else {
        setInfo('')
      }
    }, [error])

    // useEffect(() => {
    //   if (alternativeMode !== undefined) {
    //     setRenderedEquation(
    //       transformEquationPiecesIntoStringEquation(
    //         existingEquation,
    //         alternativeMode.indicatorsDict
    //       ).equation
    //     )
    //   }
    // }, [existingEquation])

    useEffect(() => {
      setAvailableIndicators(currentAvailableIndicators)
    }, [currentAvailableIndicators])

    return (
      <div className="combine-indicator-formula">
        {error !== '' && (
          <span className="banner-strip small danger">{error}</span>
        )}
        {info !== '' && <span className="banner-strip small info">{info}</span>}
        <div className="flex flex-wrap col-12">
          {availableIndicators.map((indicator, index) => {
            // value might be missing some essentials from the equation

            // value = {
            //   ...value,
            //   aggregationMethod:
            //     equationValue?.aggregationMethod || value.aggregationMethod,
            //   mode: equationValue?.mode || value.mode,
            //   offset: equationValue?.offset || value.offset,
            //   cagr: equationValue?.cagr || value.cagr
            // }

            if (!indicator) return null

            return (
              <div
                key={index}
                className="flex flex-wrap col-12 items-start self-start p-1 py-2 gap-1 border-b-2"
              >
                <div className="flex col-12 justify-between">
                  <p className="m-0 col-10 max-w-[100%] text-ellipsis overflow-hidden whitespace-nowrap font-bold">
                    {dict[index]} ={' '}
                    {
                      allIndicators.find((_) => _.fid === indicator.value)
                        ?.title
                    }
                  </p>
                  <FunctionalButton
                    iconDelete
                    disabled={availableIndicators.length === 1}
                    className="no-btn icon small scale-75"
                    initialButtonState={'Delete'}
                    functionToExecute={() => {
                      setAvailableIndicators((prev) =>
                        prev.filter((_) => _.value !== indicator.value)
                      )

                      setEquation((prev) =>
                        prev.filter((_) => _.value !== indicator.value)
                      )

                      setShowCAGR((prev) =>
                        prev.filter((_) => _ !== indicator.value)
                      )

                      setShowTrendline((prev) =>
                        prev.filter((_) => _ !== indicator.value)
                      )
                    }}
                    noReturn
                  />
                </div>
                <div className="flex col-12 gap-2">
                  <span>Aggregation Method</span>
                  <select
                    className="inline ms-0 mx-1"
                    defaultValue={indicator.aggregationMethod}
                    onChange={(e) => {
                      const changed = e.target.value as AggregationMethod

                      updateIndicatorsChange({
                        ...indicator,
                        aggregationMethod: changed
                      })
                    }}
                  >
                    <option value="first">First of Period</option>
                    <option value="last">Last of Period</option>
                    <option value="average">Average</option>
                    <option value="sum">Sum</option>
                  </select>
                </div>
                <div className="flex col-12 gap-1">
                  <p className="m-0">Number of Periods to Offset</p>
                  <input
                    type="number"
                    className="narrowest inline"
                    placeholder="offset"
                    defaultValue={indicator.offset}
                    onChange={(e) => {
                      const changed = e.target.value

                      updateIndicatorsChange({
                        ...indicator,
                        offset: Number(changed)
                      })
                    }}
                  />
                </div>
                <div className="flex col-12 gap-1">
                  <span>Mode</span>
                  <select
                    className="inline mx-1"
                    defaultValue={
                      indicator.trendlineParameters
                        ? 'showTrendline'
                        : indicator.mode?.split('_')[1]
                    }
                    onChange={async (e) => {
                      let changed = e.target.value
                      if (changed === 'showTrendline') {
                        setShowTrendline((prev) => [...prev, indicator.value])
                        updateIndicatorsChange({
                          ...indicator,
                          mode: 'values_absolute' as CreateSeriesFromDataAndModeType
                        })
                        return
                      }

                      if (changed === 'showCAGR') {
                        setShowCAGR((prev) => [...prev, indicator.value])
                        updateIndicatorsChange({
                          ...indicator,
                          mode: 'values_absolute' as CreateSeriesFromDataAndModeType
                        })
                        return
                      }

                      setShowCAGR((prev) =>
                        prev.filter((_) => _ !== indicator.value)
                      )
                      setShowTrendline((prev) =>
                        prev.filter((_) => _ !== indicator.value)
                      )

                      if (changed === 'values_absolute') {
                        changed = 'absolute'
                      }

                      updateIndicatorsChange({
                        ...indicator,
                        mode: ('values_' +
                          changed) as CreateSeriesFromDataAndModeType,
                        cagr: 0,
                        trendlineParameters: undefined
                      })
                    }}
                  >
                    <option value="absolute">Absolute</option>
                    <option value="percentChangeFromYear">
                      Percent Change from Year
                    </option>
                    <option value="changeAndPercentChangeFromYear">
                      Change from Year
                    </option>
                    <option value="monthlyPercentChange">
                      Monthly Percent Change
                    </option>
                    <option value="annualCompoundedRate">
                      Annual Compounded Rate
                    </option>
                    <option value="continuousRate">Continuous Rate</option>
                    <option value="continuousAnnualRate">
                      Continuous Annual Rate
                    </option>
                    <option value="indexScaled">Index Scaled</option>
                    <option value="showCAGR">Growth Rate of ...</option>
                    <option value="showTrendline">Trendline ...</option>
                  </select>
                  {showCAGR.includes(indicator.value) && (
                    <input
                      type="number"
                      placeholder="CAGR"
                      className="narrowest inline"
                      value={indicator.cagr}
                      onChange={(e) => {
                        const changed = e.target.value

                        updateIndicatorsChange({
                          ...indicator,
                          mode: 'values_absolute' as CreateSeriesFromDataAndModeType,
                          cagr: Number(changed)
                        })
                      }}
                    />
                  )}
                </div>
                {showTrendline.includes(indicator.value) && (
                  <div className="flex col-12 gap-2 pt-1">
                    {indicator.trendlineParameters && (
                      <span>
                        Using {indicator.trendlineParameters.chartMode}{' '}
                        trendline
                        <br />
                        range:{' '}
                        {transformFetchedRangedDetailsScenariotoRangeInputsSlash(
                          indicator.trendlineParameters.rangeChosen
                        ).join(' - ') || 'all'}
                        <br />
                        type:{' '}
                        {indicator.trendlineParameters.standardDeviationRelative
                          ? 'relative'
                          : 'absolute'}
                        <br />
                        mode: {indicator.trendlineParameters.mode}
                        <br />
                        {indicator.trendlineParameters.inverse && 'inverse'}
                      </span>
                    )}

                    <PopupModalNew
                      title=""
                      size="largest"
                      fid={generateRandomId()}
                      handleSubmit={() => undefined}
                      saveButtonExists={false}
                      inner
                      noChanges
                      className="p-0"
                      buttonFragment={{
                        props: {
                          className: 'inline',
                          functionToExecute: () => undefined,
                          noReturn: true,
                          doesReset: true,
                          initialButtonState: 'Configure Trendline Use',
                          iconPlusMode: true,
                          combinedButtonTitle: true
                        }
                      }}
                    >
                      <CreateTrendlineNew
                        equationPiece={indicator}
                        frequency={frequency}
                        updateIndicator={updateIndicatorsChange}
                      />
                    </PopupModalNew>
                  </div>
                )}
              </div>
            )
          })}
        </div>

        <div className="col-12 p-1 position-relative">
          <input
            type="text"
            onChange={(e) => setRenderedEquation(e.target.value)}
            value={renderedStringEquation}
            placeholder="Enter equation"
            className="col-12"
          />
          <ToggleInfo>
            <Instructions />
          </ToggleInfo>
        </div>
        <div className="operation-buttons">
          {(checkCalcEquationChanges(initialEquation, equation) ||
            checkCalcEquationChanges(
              availableIndicators,
              initialAvailableIndicators
            ) ||
            renderedStringEquation !== '') && (
            <div className="control-buttons">
              <FunctionalButton
                className="secondary center middle"
                functionToExecute={handleUpdate}
                doesReset
                initialButtonState="Preview"
              />
              <FunctionalButton
                className="teriary center middle"
                functionToExecute={resetEquation}
                initialButtonState="Undo"
                doesReset
              />
            </div>
          )}
        </div>

        {children}
      </div>
    )
  } catch (error) {
    console.error(error)
    return <div>Error in CombineIndicatorFormula</div>
  }
}

export default CombineIndicatorFormula
