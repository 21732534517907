import React, { Fragment, useMemo, useRef, useState } from 'react'
// import TicksWrapper from './TicksWrapper'
import {
  IDataMappedComponentProps,
  IMappedDataEntry,
  ITrendline
  //   IBasicIndicator
} from '../utils/interfaces'
import { isEmpty, transformFullDateToMonthAndYear } from '../utils/functions'
import { findMinChartIndex } from '../charts/_googleChartFunctions'
// import TicksWrapper from './TicksWrapper'
import TableRow from './TableRow'
import { Spinner, TabbingSwitch } from './_components'
// import { IconSettings } from './Icons'

const DataTable = ({
  data,
  filteredKeys,
  fetchedIndicators,
  fullDates,
  dataToCompareTo,
  activeIndicator,
  componentMode,
  onValuesClick,
  noCalcCondition,
  showOther,
  readOnly,
  rangeSelected,
  dataMode,
  selectedDataMappedMode,
  onClicks,
  indent,
  keysToHide,
  tableMode
}: IDataMappedComponentProps) => {
  if (
    data === undefined ||
    data.data.length === 0 ||
    !fetchedIndicators ||
    !filteredKeys ||
    filteredKeys.length === 0
  ) {
    return (
      <Spinner
        options={{
          noMessage: true,
          noSpinner: true
        }}
      />
    )
  }

  const [showMode, setShowMode] = useState<string>('values')
  const [loading, setLoading] = useState<boolean>(true)
  const keysToShow = useMemo(() => {
    let keys = data.data.map((entry) => entry.title)
    if (filteredKeys !== 'auto') {
      keys = typeof filteredKeys === 'string' ? [filteredKeys] : filteredKeys
    }

    if (keysToHide) {
      keys = keys.filter((key) => !keysToHide.includes(key))
    }

    return keys
  }, [filteredKeys])

  const dataSliced = useMemo(() => {
    setLoading(true)
    const finalData = data.data
      .filter((item) => {
        if (showMode === 'values') {
          return item.title.includes('Value')
        } else {
          return item.title.includes('Deviation')
        }
      })
      .map((entry) => ({
        ...entry,
        title: (entry as any).newTitle || entry.title
      }))

    if (finalData.length === 0) {
      setLoading(false)
      return data.data
    }

    if (!rangeSelected || rangeSelected.filter(isEmpty).length > 0) {
      setLoading(false)
      return finalData
    }

    const [start, end] = rangeSelected

    const mapped = finalData.map((entry) => {
      const firstIndex = findMinChartIndex(start, entry.dates)
      const lastIndex = findMinChartIndex(end, entry.dates)

      return {
        ...entry,
        dates: entry.dates.slice(firstIndex, lastIndex + 1),
        values: entry.values.slice(firstIndex, lastIndex + 1)
      }
    })
    setLoading(false)

    if (mapped.length === 0) {
      return finalData
    }

    return mapped
  }, [data.data, showMode])

  const firstIndex = useRef(
    findMinChartIndex(dataSliced[0].dates[0], fullDates) || 0
  )

  const lastDate = useRef(
    findMinChartIndex(
      dataSliced[0].dates[dataSliced[0].dates.length - 1],
      fullDates
    ) + 1 || fullDates.length
  )

  const selectedColumns = useMemo(
    () => window.globalSettings.data_mapped_selected_columns,
    [window.globalSettings.data_mapped_selected_columns]
  )

  if (loading) {
    return (
      <Spinner
        options={{
          noMessage: true,
          noSpinner: true
        }}
      />
    )
  }

  return (
    <Fragment>
      <div className="container w-12 no-borders flex justify-content-between inline entity-parameters">
        <TabbingSwitch
          tabbingLook
          options={[
            {
              label: 'Values',
              active: showMode === 'values',
              onClick: () => setShowMode('values')
            },
            {
              label: 'Deviations',
              active: showMode === 'deviations',
              onClick: () => setShowMode('deviations')
            }
          ]}
          className="col-6"
        />
      </div>
      <div className="container w-12 scroll-x">
        <table
          className={tableMode === 'mini' ? 'data-table mini' : 'data-table'}
        >
          <thead>
            <tr>
              {tableMode !== 'mini' && <th className="left options icon "></th>}
              {tableMode !== 'mini' && onClicks !== undefined && indent && (
                <th className="left options icon "></th>
              )}
              {selectedColumns.includes('base') && tableMode === 'full' && (
                <th className="large options base left">{'Base'}</th>
              )}
              {selectedColumns.includes('title') && (
                <th className="large options base left">Title</th>
              )}
              {selectedColumns.includes('from') && tableMode === 'full' && (
                <th className="small options left">From</th>
              )}
              {selectedColumns.includes('to') && tableMode === 'full' && (
                <th className="small options left">To</th>
              )}
              {selectedColumns.includes('mode') && (
                <th className="options left">Mode</th>
              )}
              {selectedColumns.includes('state') && (
                <th className="small options left">Abs/rel</th>
              )}
              {selectedColumns.includes('inverse') && (
                <th className="small options left">Inverse</th>
              )}
              {selectedColumns.includes('deviation') && (
                <th className="options left">Deviation</th>
              )}
              {selectedColumns.includes('links') && (
                <th className="small options"># Links</th>
              )}
              {/* <th className="settings">
                <button
                  className="icon no-btn"
                  onClick={() => setModalSelectionOpen(true)}
                >
                  <IconSettings />
                </button>
              </th> */}
              {(tableMode === 'mini'
                ? fullDates
                    .slice(firstIndex.current, lastDate.current + 1)
                    .slice(-3)
                    .reverse()
                : fullDates.slice(firstIndex.current, lastDate.current + 1)
              ).map((date: string, index: number) => (
                <th
                  className="value left"
                  key={`${transformFullDateToMonthAndYear(date)}_${index}`}
                >
                  <span>{transformFullDateToMonthAndYear(date)}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="default-text">
            {[
              'Calculated',
              'Values',
              'Forecasts',
              'Deviations',
              showOther ? 'Other' : ''
            ]
              .filter((section) => section !== '')
              .flatMap((section) => [
                dataSliced.map((entry: IMappedDataEntry, index: number) => {
                  let indicator = fetchedIndicators.find(
                    (ind) => ind.fid === entry.title
                  )

                  if (
                    !indicator &&
                    showOther &&
                    keysToShow.includes(entry.title)
                  ) {
                    // this is a workaround for the case whenthere is no indicator
                    indicator = {
                      type: 'other',
                      title: entry.title
                    } as ITrendline
                  }

                  // Determine if the current entry should be shown based on the section
                  const shouldDisplay = (() => {
                    if (!indicator) return false
                    if (
                      (noCalcCondition !== undefined &&
                        noCalcCondition(entry.title)) ||
                      !keysToShow.includes(entry.title)
                    ) {
                      return false
                    }

                    switch (section) {
                      case 'Values':
                        return (
                          indicator.type === 'actuals' ||
                          indicator.type === 'indicator'
                        )
                      case 'Forecasts':
                        return indicator.type === 'forecast'
                      case 'Deviations':
                        return indicator.type === 'trendline'
                      case 'Calculated':
                        return indicator.type === 'calculated'
                      case 'Other':
                        return indicator.type === 'other'
                      default:
                        return false
                    }
                  })()

                  if (!shouldDisplay) {
                    return null
                  }

                  const isActive = indicator
                    ? indicator.fid === activeIndicator
                    : false

                  // taking care of the edge case when the indicator is a trendline and the component mode is trendline
                  if (
                    indicator &&
                    componentMode === 'trendline' &&
                    showOther &&
                    indicator.title.includes(' Deviation')
                  ) {
                    const title = indicator.title.replace(' Deviation ', '')
                    const relatedIndicator = fetchedIndicators.find(
                      (ind) => ind.title === title
                    )

                    if (relatedIndicator) {
                      indicator = {
                        ...indicator,
                        type: 'other',
                        onclick: (relatedIndicator as any)?.onclick
                      }

                      if (onClicks) {
                        onClicks?.push({
                          key: indicator!.title,
                          onClick: (relatedIndicator as any)?.onclick,
                          onEntityClick: () =>
                            window.switchFunctions.trendline(
                              relatedIndicator.fid
                            )
                        })
                      }
                    }
                  }

                  return (
                    <TableRow
                      key={`${section}-${index}`}
                      isActiveMode={isActive}
                      data={entry}
                      tableMode={tableMode}
                      dates={fullDates.slice(
                        firstIndex.current,
                        lastDate.current + 1
                      )}
                      indicatorParameterFetched={indicator as ITrendline}
                      selectedColumns={selectedColumns}
                      dataMode={tableMode === 'mini' ? 'deviations' : dataMode}
                      fullDates={fullDates}
                      selectedDataMappedMode={selectedDataMappedMode}
                      onClicks={onClicks?.find(
                        (click) => click.key === entry.title
                      )}
                      onValuesClick={
                        onValuesClick && !readOnly
                          ? () => onValuesClick(entry.title)
                          : undefined
                      }
                      noCalc={
                        noCalcCondition !== undefined
                          ? noCalcCondition(entry.title)
                          : false
                      }
                      componentMode={componentMode}
                      readOnly={readOnly}
                    />
                  )
                })
              ])}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default DataTable
