import React, { useEffect, useRef, useState } from 'react'
import {
  convertExternalToPlatformIndicator,
  fetchExternalIndicator,
  getAllTrendlinesForExternalIndicator
} from '../utils/fetch'
import {
  // ICollection,
  ICollectionEntry,
  IBasicIndicator,
  ITrendline,
  IEntityAllKeys,
  IIndicatorPage,
  IDataMapped,
  ICalculatedIndicator,
  IIndicator
} from '../utils/interfaces'
import {
  AccessDenied,
  FunctionalButton,
  Spinner,
  EntityHeader,
  AnalyseWithAI
} from '../helperComponents/_components'
import {
  exportToExcel,
  // findLowestFrequencyFromParams,
  generateIndicatorsKeysObjects,
  getAllValuesGroupedByKey,
  returnMergedByDate
} from '../utils/transformingData'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
// import CustomContextMenu from '../helperComponents/CustomContextMenu'

// import { Icon3Dots } from '../helperComponents/Icons'
import {
  evaluateLocalStoragePageMode,
  findMatchPercentage,
  generateRandomId,
  isEmpty,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
// import { Trendlines } from './Trendlines'
import PopupModalNew from '../helperComponents/PopUpModalNew'
import CreateTrendline from '../trendlines/CreateTrendline'
import { ChartLine } from '../charts/interfaces'
import DataEntityParameters from '../helperComponents/DataEntityParameters'
// import DataTableParameters from '../helperComponents/DataTableParameters'
import DataTableValues from '../helperComponents/DataTableValues'

const ExternalPage = ({
  indicatorId,
  userTags,
  readonly,
  favourites
}: IIndicatorPage) => {
  try {
    const files = useRef<File[] | null>(null)
    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const fetchedTrendlines = useRef<ITrendline[]>([])
    const fullFetchedIndicatorsParameters = useRef<ITrendline[]>([])
    const fetchedDataTransformed = useRef({} as IDataMapped)
    const fetchedIndicatorData = useRef<any[]>([])
    const fetchedAllIndicators = useRef(
      [] as (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    )
    const fetchedChartLines = useRef<ChartLine[]>([])

    const fullDates = useRef<string[]>([])
    const [trendlinesFetched, setTrendlinesFetched] = useState<boolean>(false)
    const entityAllKeys = useRef<IEntityAllKeys[]>([])
    const isCurrentIndicatorFavourite = useRef<boolean>(false)
    // const defaultContextMenuSettings = useRef({
    //   top: 0,
    //   left: 0,
    //   show: false
    // })
    const fetchedIndicatorParameters = useRef<IBasicIndicator | null>(null)
    const selectedDataBoundaries = useRef(['', ''])

    const [pageMode, setPageMode] = useState<
      'basic' | 'trendline' | 'forecast'
    >('basic')
    // const [dataMappedCellWidth, setDataMappedCellWidth] = useState<number>(50)
    const [pageLoading, setPageLoading] = React.useState(false)

    // const [contextMenuDetails, setContextMenuDetails] = useState(
    //   defaultContextMenuSettings.current
    // )
    const [entityChartKeys, setEntityChartKeys] = useState<IEntityAllKeys[]>([])
    const [newName, setNewName] = useState<string>('')
    const [newShortDescription, setNewShortDescription] = useState<string>('')
    const [indicatorDataForAI, setIndicatorDataForAI] = useState<any>(null)

    // const [ownerInfo, setOwnerInfo] = useState({
    //   profileImage: '',
    //   username: ''
    // })

    // const resetContextMenuDetails = () => {
    //   if (contextMenuDetails.show) {
    //     setContextMenuDetails(defaultContextMenuSettings.current)
    //   }
    // }

    const resetModals = () => {
      window.collapseSidePanel(true)
      localStorage.removeItem('openPopupId')
      // setContextMenuDetails(defaultContextMenuSettings.current)
    }

    // const handleContextMenuDetails = (e: React.MouseEvent) => {
    //   e.preventDefault()
    //   if (contextMenuDetails.show) {
    //     setContextMenuDetails(defaultContextMenuSettings.current)
    //     return
    //   }
    //   resetModals()
    //   setContextMenuDetails({
    //     show: true,
    //     top: e.pageY,
    //     left: e.pageX + 10
    //   })
    // }

    const getCurrentPage = () => {
      return window.visitedPages[window.currentPageIndex - 1]
    }

    const addToVisitedPages = ({
      type,
      fid,
      pageMode
    }: {
      type: string
      fid: string | null | number
      pageMode?: string
    }) => {
      const currentPage = getCurrentPage()
      if (currentPage && currentPage.fid === fid && currentPage.type === type) {
        if (isEmpty(pageMode)) {
          return
        } else if (pageMode === currentPage.pageMode) {
          return
        }
      }

      const currentIndex = window.currentPageIndex
      const visitedPages = window.visitedPages
      if (visitedPages.length === currentIndex) {
        window.currentPageIndex += 1
        window.visitedPages = [
          ...visitedPages,
          {
            type,
            fid,
            pageMode
          }
        ]
      } else {
        const newVisitedPages = [
          ...visitedPages.slice(0, currentIndex),
          {
            type,
            fid,
            pageMode
          }
        ]
        window.visitedPages = newVisitedPages
        window.currentPageIndex = newVisitedPages.length
      }
    }

    const handleSwitchPageMode = (
      selectedOption: 'basic' | 'trendline' | 'forecast'
    ) => {
      resetModals()
      if (selectedOption === 'basic') {
        // ensure the indicator key is included in the chart keys and data keys
        const indicatorKey = entityAllKeys.current.find(
          (item) => item.title === indicatorId
        )

        setTrendlinesFetched(false)

        if (indicatorKey) {
          setEntityChartKeys((prevKeys) => [...prevKeys, indicatorKey])
        }
        addToVisitedPages({
          fid: indicatorId,
          type: 'external',
          pageMode: selectedOption
        })
      } else {
        addToVisitedPages({
          fid: indicatorId,
          type: 'externalTrendlines',
          pageMode: selectedOption
        })
      }

      localStorage.setItem(
        'indicatorPageMode',
        `${selectedOption}---${indicatorId}`
      )
      setPageMode(selectedOption)

      return true
    }

    const checkIfIndicatorIsFavourite = () => {
      if (favourites === undefined) {
        return false
      }

      return (
        favourites.externals.length > 0 &&
        favourites.externals.some(
          (item: ICollectionEntry) => item.id === indicatorId
        )
      )
    }

    const fetchIndicator = async (indicatorId: string, noClose?: boolean) => {
      try {
        if (!noClose) {
          resetModals()
          setPageLoading(true)
        }
        const { dataResponse, parametersResponse, allIndicators, chartLines } =
          await fetchExternalIndicator(indicatorId)

        const fetchedParameters = parametersResponse[0]
        const externalData = dataResponse

        const isFavourite = checkIfIndicatorIsFavourite()
        const { allKeys, allChartKeys } = generateIndicatorsKeysObjects({
          indicatorsParameters: [fetchedParameters as any],
          selectedChart: [indicatorId].join(', '),
          selectedData: [indicatorId].join(', '),
          dataMode: fetchedParameters.data_mode,
          backupVisibleKeys: [indicatorId]
        })

        // setOwnerInfo({
        //   profileImage: res.owner.profile_image,
        //   username: res.owner.username
        // })
        fetchedIndicatorParameters.current = fetchedParameters
        isCurrentIndicatorFavourite.current = isFavourite
        entityAllKeys.current = allKeys
        fetchedChartLines.current = chartLines
        selectedDataBoundaries.current =
          transformFetchedRangedDetailsScenariotoRangeInputs(
            fetchedParameters.range_chosen
          )
        fullDates.current = externalData.map((item: any) => item.date)
        fullFetchedIndicatorsParameters.current = [fetchedParameters as any]
        fetchedDataTransformed.current = getAllValuesGroupedByKey(externalData)
        fetchedIndicatorData.current = externalData
        fetchedAllIndicators.current = [...allIndicators, fetchedParameters]

        setNewName(fetchedParameters.title)
        setNewShortDescription(fetchedParameters.short_description)

        setEntityChartKeys(allChartKeys)

        const dataForAI = {
          name: fetchedParameters.title,
          description: fetchedParameters.meaning,
          category: fetchedParameters.category,
          short_description: fetchedParameters.short_description,
          data: externalData
        }
        setIndicatorDataForAI(dataForAI)

        if (!noClose) {
          setPageLoading(false)
        }
        setAccessAllowed(true)
      } catch (err: any) {
        console.error(err)
        window.switchFunctions.home()
      }
    }

    const fetchTrendlines = async (force = false) => {
      const keys = Object.keys(fetchedIndicatorData.current[0]).filter(
        (key) => key !== 'date' && key !== 'id' && key !== indicatorId
      )
      const existingTrendlines = entityChartKeys
        .filter((key) => key.type === 'trendline')
        .map((key) => [`${key.title} Value`, `${key.title} Deviation`])
        .flat()
      const match = findMatchPercentage(keys, existingTrendlines)

      if ((match > 50 || trendlinesFetched) && !force) {
        setTrendlinesFetched(true)
        return
      }
      setPageLoading(true)

      const { params, data } =
        await getAllTrendlinesForExternalIndicator(indicatorId)
      fetchedTrendlines.current = params

      const mergedData = returnMergedByDate(
        fetchedIndicatorData.current,
        [data],
        true
      )

      const trendlinesIds: string[] = params.map((item: any) => item.fid)
      const { allKeys, allChartKeys } = generateIndicatorsKeysObjects({
        indicatorsParameters: [
          ...fullFetchedIndicatorsParameters.current,
          ...params
        ],
        selectedChart: [indicatorId, ...trendlinesIds].join(', '),
        selectedData: [indicatorId, ...trendlinesIds].join(', '),
        dataMode: fullFetchedIndicatorsParameters.current[0].data_mode,
        backupVisibleKeys: [indicatorId]
      })

      fetchedDataTransformed.current = getAllValuesGroupedByKey(mergedData)
      fetchedIndicatorData.current = mergedData
      entityAllKeys.current = allKeys

      setEntityChartKeys(allChartKeys)
      setPageLoading(false)
    }

    const refreshIndicatorNoClose = (contextMenu?: boolean) => {
      if (pageLoading) return
      fetchIndicator(indicatorId, true).then(() => {
        if (contextMenu) {
          // setTimeout(() => {
          // resetContextMenuDetails()
          // }, 500)
        }
      })
    }

    // const applyMappedCellWidthChange = (e: any) => {
    //   if (
    //     e.target.value >= 20 &&
    //     e.target.value <= 150 &&
    //     e.target.value.toString().slice(0, 1) !== '0' &&
    //     !isNaN(e.target.value)
    //   ) {
    //     const value = e.target.value
    //     setDataMappedCellWidth(value)
    //     const tickWidth = 17 * (value / 50)

    //     // Assuming 'value' and 'tickWidth' are defined and have numeric types
    //     // 1. Set width for elements with classes .date-tick and .tick
    //     const ticks: any = document.querySelectorAll('.date-tick, .tick')
    //     ticks.forEach(
    //       (tick: { style: { width: string } }) =>
    //         (tick.style.width = `${value}px`)
    //     )

    //     // 2. Set min-width for elements with classes .date-tick and .tick
    //     ticks.forEach(
    //       (tick: { style: { minWidth: string } }) =>
    //         (tick.style.minWidth = `${value}px`)
    //     )

    //     // 3. Set padding-left to 0 for elements with the selector .ticks-entry .ticks span
    //     const spanElements: any = document.querySelectorAll(
    //       '.ticks-entry .ticks span'
    //     )
    //     spanElements.forEach(
    //       (span: { style: { paddingLeft: string } }) =>
    //         (span.style.paddingLeft = '0')
    //     )

    //     // 4. Set width for images inside elements with the class .tick
    //     const tickImages: any = document.querySelectorAll('.tick img')
    //     tickImages.forEach(
    //       (img: any) =>
    //         (img.style.width = `${tickWidth > 17 ? 17 : tickWidth}px`)
    //     )
    //   }
    // }

    const handleExportData = () => {
      try {
        if (
          !fetchedDataTransformed.current ||
          !fetchedDataTransformed.current.data ||
          !fetchedDataTransformed.current.data[0] ||
          fetchedIndicatorParameters === null ||
          !fetchedIndicatorParameters.current
        ) {
          return false
        }

        const dates = fetchedDataTransformed.current.data[0].dates
        const values = fetchedDataTransformed.current.data[0].values
        const data = values.map((item: any, index: number) => {
          return {
            date: dates[index],
            [(fetchedIndicatorParameters as any).current.title]: item
          }
        })
        exportToExcel(
          data,
          `${fetchedIndicatorParameters.current.title} - ${new Date()
            .toLocaleDateString()
            .split('/')
            .join('-')
            .slice(0, 10)}`
        )

        return true
      } catch (err: any) {
        console.error(err)
        return false
      }
    }

    const handleNewIndicator = async (indicatorId: string) => {
      if (pageLoading) return
      const pageMode = evaluateLocalStoragePageMode(indicatorId)
      if (indicatorId) {
        await fetchIndicator(indicatorId)
      }
      if (pageMode === 'trendline' && fetchedIndicatorData.current.length) {
        await fetchTrendlines()
      } else {
        setTrendlinesFetched(false)
      }
      setPageMode(pageMode)
    }

    const handleCurrentPageIndexChange = async (index: number) => {
      const currentPage = window.visitedPages[index - 1]
      if (
        currentPage.pageMode === 'trendline' &&
        fetchedIndicatorData.current.length
      ) {
        await fetchTrendlines()
      }
      if (
        currentPage.fid === indicatorId &&
        currentPage.pageMode !== pageMode
      ) {
        setPageMode((currentPage.pageMode as any) || 'basic')
      }
    }

    useEffect(() => {
      handleNewIndicator(indicatorId)
    }, [indicatorId])

    useEffect(() => {
      handleCurrentPageIndexChange(window.currentPageIndex)
    }, [window.currentPageIndex])

    if (pageLoading) return <Spinner />
    else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (
      accessAllowed &&
      !pageLoading &&
      fetchedIndicatorParameters.current
    ) {
      return (
        <div className="entity external">
          <EntityHeader
            breadcrumbs={[
              {
                title: 'Indicator',
                onClick: () => handleSwitchPageMode('basic'),
                active: pageMode === 'basic'
              }
              // ...(pageMode === 'trendline'
              //   ? [
              //       {
              //         title: 'Trendlines Analysis',
              //         onClick: () => handleSwitchPageMode('trendline'),
              //         active: pageMode === 'trendline'
              //       }
              //     ]
              //   : [])
            ]}
            subtitle="Indicator"
            ownerInfo={{ profileImage: null, username: '' }}
            title={newName}
            description={newShortDescription}
            titleClick={() => window.switchFunctions.external(indicatorId)}
          />
          <div className="entity-blocks">
            <div className="container w-12">
              <div className="entity-parameters justify-content-start">
                {pageMode === 'trendline' && (
                  <PopupModalNew
                    title="Create Trendline"
                    size="largest"
                    fid={generateRandomId()}
                    handleSubmit={() => undefined}
                    saveButtonExists={false}
                    inner
                    noChanges
                    className="p-0"
                    buttonFragment={{
                      props: {
                        className:
                          'inline no-btn default-text no-hover no-border m-2',
                        functionToExecute: () => undefined,
                        noReturn: true,
                        doesReset: true,
                        initialButtonState: 'Generate Trendline',
                        iconPlusMode: true
                      }
                    }}
                  >
                    <CreateTrendline
                      fetchedDataTransformed={
                        fetchedDataTransformed.current.data.filter(
                          (entry) => entry.title === indicatorId
                        )[0]
                      }
                      fetchedIndicatorParameters={
                        fetchedIndicatorParameters.current
                      }
                      indicatorId={indicatorId}
                      fullDates={fullDates.current}
                      source="external"
                      source_fid={indicatorId}
                      closeModal={resetModals}
                    />
                  </PopupModalNew>
                )}
                {/* {!readOnly.current && pageMode !== 'trendline' && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border"
                    fakeLoading
                    noReturn
                    functionToExecute={() => handleSwitchPageMode('trendline')}
                    doesReset
                    initialButtonState={'Trendlines Analysis'}
                    iconTrendline
                  />
                )} */}
                <AnalyseWithAI
                  analyseParameters={indicatorDataForAI}
                  files={files}
                  analysisMode="component"
                  instructions="Examine the document detailing the indicator and focus on the values over different time periods, identifying any significant trends or changes. Highlight key moments where the values experienced notable declines, explaining each drop by comparing it to previous data and evaluating its significance. Provide potential causes for each decline, considering factors such as market events, external influences, or internal system changes. Based on the patterns and historical data, assess possible future movements of the values and explain why certain trends may continue, reverse, or stabilize. Finally, summarize the analysis and mention other areas where you can assist."
                />
                {/* {pageMode === 'basic' && (
                  <button
                    className="inline no-btn default-text no-hover no-border"
                    onClick={handleContextMenuDetails}
                  >
                    <Icon3Dots />
                  </button>
                )} */}
                <FunctionalButton
                  className="inline default-text no-hover"
                  functionToExecute={async () =>
                    await convertExternalToPlatformIndicator(indicatorId)
                  }
                  initialButtonState="Add to My Library"
                  iconPlusMode
                  doesReset
                  combinedButtonTitle
                />
              </div>
            </div>
            {pageMode === 'basic' && (
              <div className="container w-6 large scroll no-scrollbar">
                <div className="entity-chart">
                  {/* {readOnly && <span className="banner-strip small warning col-12">Read Only</span>} */}
                  <HighChartsLinearChart
                    pageDetails={{
                      type: 'external',
                      title: newName,
                      fid: indicatorId,
                      refresh: refreshIndicatorNoClose
                    }}
                    readOnly={true}
                    page="indicator"
                    allIndicators={fetchedAllIndicators.current}
                    chartOptions={{
                      xAxisTitle: 'Month',
                      yAxisTitle: 'Value',
                      title: newName,
                      reactive: true,
                      mediumSize: true
                      // reactive: initialChartKeys !== entityChartKeys
                    }}
                    chartData={{
                      chartLines: fetchedChartLines.current,
                      fetchedData: fetchedIndicatorData.current,
                      fullDates: fullDates.current,
                      titles: entityChartKeys.map((item: IEntityAllKeys) => ({
                        [item.title]: item.name
                      })),
                      filteredKeys: [indicatorId]
                      // deviations: fullFetchedIndicatorsParameters
                    }}
                    componentOptions={{
                      own:
                        fetchedIndicatorParameters.current &&
                        !fetchedIndicatorParameters.current.admin_access,
                      parametersFetched: fetchedIndicatorParameters.current
                    }}
                  />
                </div>
              </div>
            )}
            {pageMode === 'basic' && (
              <div
                className="container w-6 large no-scrollbar"
                // onClick={resetContextMenuDetails}
              >
                <div className="entity-data">
                  <DataEntityParameters
                    page="external"
                    readOnly={readonly}
                    fid={indicatorId}
                    fetchedIndicatorParameters={
                      fetchedIndicatorParameters.current
                    }
                    fetchedDataTransformed={fetchedDataTransformed.current}
                    userTags={userTags}
                    refreshEntityNoClose={refreshIndicatorNoClose}
                    handleExportData={handleExportData}
                    files={files.current}
                    aiInstructions="Examine the document detailing the indicator and focus on the values over different time periods, identifying any significant trends or changes. Highlight key moments where the values experienced notable declines, explaining each drop by comparing it to previous data and evaluating its significance. Provide potential causes for each decline, considering factors such as market events, external influences, or internal system changes. Based on the patterns and historical data, assess possible future movements of the values and explain why certain trends may continue, reverse, or stabilize. Finally, summarize the analysis and mention other areas where you can assist."
                  />
                </div>
              </div>
            )}
            {pageMode === 'basic' && (
              <div className="container w-12 fit">
                {/* <div
                  className="container w-12 scroll-x no-borders"
                  // onClick={resetContextMenuDetails}
                >
                  <DataTableParameters
                    indicators={fullFetchedIndicatorsParameters.current}
                  />
                </div> */}
                <div className="container w-6 fit scroll-x">
                  <DataTableValues
                    data={fetchedIndicatorData.current}
                    parameters={
                      [fetchedIndicatorParameters.current] as IIndicator[]
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering indicator page:', error)
    return <AccessDenied />
  }
}

export default ExternalPage
