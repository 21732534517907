import React, { Fragment, useEffect, useState } from 'react'
import {
  FunctionalToggle,
  ToggledCollapsibleBlock
} from '../../helperComponents/_components'
import {
  ChartLineAppearanceComponentProps,
  ChartLineAppearanceSettings
} from '../interfaces'

const AppearanceBlock = ({
  initialSettings,
  updateSettings
}: ChartLineAppearanceComponentProps) => {
  const [appearanceSettings, setAppearanceSettings] =
    useState<ChartLineAppearanceSettings>(initialSettings)

  const handleFormatSelectedSeries = (
    newSettings: Partial<ChartLineAppearanceSettings>
  ) => {
    setAppearanceSettings((prevSettings) => ({
      ...prevSettings,
      ...newSettings
    }))
  }

  useEffect(() => {
    updateSettings(appearanceSettings)
  }, [appearanceSettings])

  return (
    <Fragment>
      <div className="flex gap-2">
        <ToggledCollapsibleBlock
          title="Appearance"
          initialState={true}
          className="t-small-children"
        >
          <div className="flex space-between col-12 my-2">
            <div className="flex col-6 py-2">
              <label>Color:</label>
              <input
                type="color"
                value={appearanceSettings.color || '#7cb5ec'}
                onChange={(e) =>
                  handleFormatSelectedSeries({
                    color: e.target.value
                  })
                }
              />
            </div>
            <div className="flex col-6 py-2">
              <label>Line Width:</label>
              <input
                className="inline"
                type="number"
                value={appearanceSettings.width || 1}
                onChange={(e) =>
                  handleFormatSelectedSeries({
                    width: Number(e.target.value)
                  })
                }
                max={15}
              />
            </div>
            <div className="flex col-6 py-2">
              <label>Line Style:</label>
              <select
                value={appearanceSettings.dashStyle || 'Solid'}
                className="inline"
                onChange={(e) =>
                  handleFormatSelectedSeries({
                    dashStyle: e.target.value
                  })
                }
              >
                <option value="Solid">Solid</option>
                <option value="Dash">Dash</option>
                <option value="Dot">Dot</option>
              </select>
            </div>
            <div className="flex col-6">
              <label className="margin-right-1">Preferred Y-Axis:</label>
              <FunctionalToggle
                leftTitle="Left"
                rightTitle="Right"
                state={appearanceSettings.yAxis === 'right'}
                functionToExecute={() => {
                  handleFormatSelectedSeries({
                    yAxis:
                      appearanceSettings.yAxis === 'left' ? 'right' : 'left'
                  })
                }}
                disabled={false}
              />
            </div>
          </div>
        </ToggledCollapsibleBlock>
      </div>
    </Fragment>
  )
}

export default AppearanceBlock
