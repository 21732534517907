import React, { useMemo, useRef } from 'react'
import { IFetchedDataEntry, IIndicator } from '../utils/interfaces'
import { ScrollTableButtons } from './_components'
import { transformFullDateToMonthAndYear } from '../utils/functions'

const DataTableValues = ({
  data,
  parameters
}: {
  data: IFetchedDataEntry[]
  parameters: IIndicator[]
}) => {
  const dataToUse = useMemo(
    () =>
      data.map(
        (_) =>
          ({
            ..._,
            date: transformFullDateToMonthAndYear(_.date)
          }) as IFetchedDataEntry
      ),
    [data]
  )

  const keys = useRef(
    data.length > 0
      ? [
          'date',
          ...Object.keys(data[0]).filter(
            (key) => key !== 'id' && key !== 'date'
          )
        ]
      : []
  )

  const handleMoveToEntityPage = (key: any) => {
    const parameter = Array.isArray(parameters)
      ? parameters.find((param) => param.fid === key)
      : undefined
    if (parameter) {
      switch (parameter.type) {
        case 'indicator':
        case 'actuals':
        case 'custom':
        case 'original':
        case 'internal': // ????
          return window.switchFunctions.indicator(parameter.fid)
        case 'calculated':
          return window.switchFunctions.calculated(parameter.fid)
        case 'external':
          return window.switchFunctions.external(parameter.fid)
        case 'forecast':
          return window.switchFunctions.forecast(parameter.fid)
        case 'trendline':
          return window.switchFunctions.trendline(parameter.fid)
        default:
          return null
      }
    }
    return null
  }

  return (
    <>
      <ScrollTableButtons />
      <div className="data-table-values-scroll-container">
        <table className="data-table-values">
          <tbody>
            {keys.current.map((key) => (
              <tr key={key}>
                <td
                  className="data-table-values__key"
                  title={
                    Array.isArray(parameters)
                      ? parameters.find((param) => param.fid === key)?.title ||
                        key
                      : key
                  }
                  onClick={() => handleMoveToEntityPage(key)}
                >
                  {Array.isArray(parameters)
                    ? parameters.find((param) => param.fid === key)?.title ||
                      key
                    : key}
                </td>
                {dataToUse.map((entry) => (
                  <td className="data-table-values__value" key={entry.id}>
                    {entry[key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default DataTableValues
